<template>
  <div ref="Contribution" v-loading="loading" class="card contribution">
    <exportBtn
      :request-func="requestFunc"
      :params="{
        id: $route.query.id,
        type: 3,
        subjectId: subject,
      }"
    >
      <div class="section-title">学科贡献分析</div>
    </exportBtn>
    <div class="filter">
      <div class="section-sub-title">年级组学科贡献</div>
      <div class="row">
        <div class="label">选择段次：</div>
        <el-select
          v-model="level"
          style="width: 112px"
          placeholder="请选择"
          @change="getresearchreportsubjectcontributionData"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="tableList">
      <el-table
        ref="elTable"
        :data="gradeList"
        :header-cell-style="{ background: '#f5f7fa' }"
        border
        style="width: 100%"
      >
        <el-table-column prop="subjectName" label="学科" align="center">
        </el-table-column>
        <el-table-column prop="examTotal" label="参考人数" align="center">
        </el-table-column>
        <el-table-column prop="total" label="上线人数" align="center">
        </el-table-column>
        <el-table-column prop="ep" label="有效分" align="center">
        </el-table-column>
        <el-table-column prop="proportion" label="上线率（%）" align="center">
          <template slot-scope="{ row }"> {{ row.proportion }} </template>
        </el-table-column>
        <el-table-column
          prop="effectiveTotal"
          label="有效上线人数"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="effectiveRate"
          width="220px"
          label="命中率（有效上线率）（%）"
          align="center"
        >
          <template slot-scope="{ row }"> {{ row.effectiveRate }} </template>
        </el-table-column>
        <el-table-column
          prop="contributionRate"
          label="贡献率（%）"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.contributionRate }}
          </template>
        </el-table-column>
        <el-table-column
          prop="contributionLevel"
          label="贡献等级"
          align="center"
        >
          <template slot-scope="{ row }">
            <span :class="row.contributionLevel">{{
              row.contributionLevel
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="model-desc">
      <div>
        <span class="A">A</span>:命中率 ≥ 60%，贡献率 ≥
        70%&nbsp;&nbsp;&nbsp;&nbsp;结论：单科与总分上线匹配较好
      </div>
      <div>
        <span class="B">B</span>:命中率 &lt; 60%，贡献率 ≥
        70%&nbsp;&nbsp;&nbsp;&nbsp;结论：单科上线优势或单科冒进 原因：1.
        单科教学有效性高 2. 单科投入过多
      </div>
      <div>
        <span class="C">C</span>:命中率 ≥ 60%，贡献率 &lt;
        70%&nbsp;&nbsp;&nbsp;&nbsp;结论：单科滞后 原因：1. 单科教学有效性较低 2.
        单科投入不足
      </div>
      <div>
        <span class="D">D</span>:命中率 &lt; 60%，贡献率 &lt;
        70%&nbsp;&nbsp;&nbsp;&nbsp;结论：单科上线与总分上线匹配不好
      </div>
      <div>注：1.本报告中上线有关计算的基数均为参考人数。</div>
      <div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.有效上线人数指单科与总分同时上线的人数。命中率为单科有效上线人数/单科上线人数；非选考科目的贡献率为单科有效上线人数/总分上线人数，选考科目的贡献率为单科有效上线人数/选考该科且总分上线人数。
      </div>
    </div>
    <template v-isUnionShow="'statClass'">
      <div class="filter">
        <div class="section-sub-title">班级学科贡献</div>
        <!-- <check-component :source="classNumList" @change="getCheckClassNum" /> -->
      </div>
      <div class="tableList">
        <el-table
          ref="classList"
          :data="classList"
          :header-cell-style="{ background: '#f5f7fa' }"
          style="width: 100%"
          border
        >
          <el-table-column prop="classNum" label="班级" align="center">
          </el-table-column>
          <el-table-column prop="teacherName" label="任课教师" align="center">
          </el-table-column>
          <el-table-column prop="examTotal" label="参考人数" align="center">
          </el-table-column>
          <el-table-column prop="total" label="上线人数" align="center">
          </el-table-column>
          <el-table-column prop="proportion" label="上线率（%）" align="center">
            <template slot-scope="{ row }"> {{ row.proportion }} </template>
          </el-table-column>
          <el-table-column
            prop="effectiveTotal"
            label="有效上线人数"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="effectiveRate"
            label="命中率（有效上线率）%"
            align="center"
            width="200"
          >
            <template slot-scope="{ row }"> {{ row.effectiveRate }} </template>
          </el-table-column>
          <el-table-column
            prop="contributionRate"
            label="贡献率（%）"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.contributionRate }}
            </template>
          </el-table-column>
          <el-table-column
            prop="contributionLevel"
            label="贡献等级"
            align="center"
          >
            <template slot-scope="{ row }">
              <span :class="row.contributionLevel">{{
                row.contributionLevel
              }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="old.length > 10" class="btns">
          <el-button type="text" @click="isExtendChange">{{
            isExtend ? "收起" : "查看更多"
          }}</el-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  getresearchreportsubjectcontribution,
  exportTeachingReport,
  getstatInfo,
} from "@/core/api/academic/teachingReport";
import exportBtn from "@/views/academic/components/exportBtn.vue";
import { Uniongetresearchreportsubjectcontribution } from "@/core/api/academic/unionReport";
// import checkComponent from "./checkComponent.vue";
import {
  getstatonlineoption,
  getstatclassoption,
} from "@/core/api/academic/common";
import { dataMethod } from "./methods";
import axios from "axios";
export default {
  name: "Contribution",
  components: {
    exportBtn,
  },
  props: {
    subject: {
      type: [String, Number, null],
      default: null,
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      requestFunc: exportTeachingReport,
      statId: this.$route.query.id,
      examType: this.$route.query.examType,
      options: [],
      classOptions: [],
      level: "",
      classLevel: "",
      tableData: [],
      gradeList: [],
      classList: [],
      subjectList: [],
      classNumList: [],
      classNum: "",
      old: [],
      io: {},
      isExtend: false,
      loading: false,
    };
  },

  mounted() {
    this.getstatInfoData();
  },
  methods: {
    isExtendChange() {
      this.isExtend = !this.isExtend;
      this.classList = this.isExtend ? this.old : this.old.slice(0, 10);
    },
    objectSpanMethod({ columnIndex, column, rowIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        let _row = this.spanObj[column.property][rowIndex];
        let _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    // getCheckClassNum(val) {
    //   let arr = val.map((item) => item.name);
    //   this.classNum = arr.toString();
    //   this.getresearchreportsubjectcontributionData();
    // },
    getLevelColor(row) {
      if (row.onlineRateEffective >= 60 && row.contributionRate >= 70) {
        return "A";
      } else if (row.onlineRateEffective < 60 && row.contributionRate >= 70) {
        return "B";
      } else if (row.onlineRateEffective >= 60 && row.contributionRate < 70) {
        return "C";
      } else if (row.onlineRateEffective < 60 && row.contributionRate < 70) {
        return "D";
      }
    },
    async getresearchreportsubjectcontributionData() {
      try {
        this.loading = true;
        let res;
        if (this.$route.query.examType == 1) {
          res = await Uniongetresearchreportsubjectcontribution({
            id: this.statId,
            onlineId: this.level,
            subjectId: this.subject,
            viewSchoolId: this.$route.query.schoolId,
            classNums: this.classNum,
          });
        } else {
          res = await getresearchreportsubjectcontribution({
            id: this.statId,
            onlineId: this.level,
            subjectId: this.subject,
            classNums: this.classNum,
          });
        }

        const {
          researchReportSubjectContributionClassSubjectVOs,
          researchReportSubjectContributionSubjectVO,
        } = res.data.data;
        this.gradeList = [researchReportSubjectContributionSubjectVO];
        this.spanObj = dataMethod(
          researchReportSubjectContributionClassSubjectVOs,
          ["classNum", "teacherName"]
        );
        this.classList = researchReportSubjectContributionClassSubjectVOs.slice(
          0,
          10
        );
        this.old = researchReportSubjectContributionClassSubjectVOs;
        this.isExtend = false;
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
          this.$refs.classList.doLayout();
        });
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getData() {
      this.loading = true;
      try {
        if (this.subject) {
          const [onlineRes, classRes] = await axios.all([
            getstatonlineoption({ id: this.statId }),
            getstatclassoption({
              id: this.statId,
              viewSchoolId:
                this.examType == 1 ? this.$route.query.schoolId : null,
              requestPage: 2,
            }),
          ]);
          this.options = onlineRes.data.data;
          this.level = onlineRes.data.data && onlineRes.data.data[0].id;
          this.classNumList = classRes.data.data;
          let arr = this.classNumList.map((item) => item.name);
          this.classNum = arr.toString();
          this.getresearchreportsubjectcontributionData();
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    assembleClassList(arr) {
      return arr.map((item) => {
        return item.contributionGradeBaseVOList.map((it) => {
          return {
            classNum: item.classNum,
            teacherName: item.teacherName,
            ...it,
          };
        });
      });
    },
    async getstatInfoData() {
      // 获取统计科目
      const res = await getstatInfo({ id: this.statId });
      this.subjectList = res.data.data.statSubjects;
      this.getData();
    },
    getSubjectName(id) {
      if (!Number(id)) {
        return "总分";
      } else {
        const data = this.subjectList.find((item) => item.subjectId === id);
        return data.name;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.contribution {
  .filter {
    margin: 28px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .row {
      display: flex;
      align-items: center;

      .label {
        font-size: 14px;
        color: #080a09;
      }
    }
  }

  .tableList {
    margin-bottom: 32px;

    ::v-deep tbody tr:hover > td {
      background-color: #fff5d7;
    }
  }
}

.tag {
  font-size: 14px;
  border: 1px dashed #2474ed;
  color: #737677;
  background-color: #f2f8fe;
  box-sizing: border-box;
  padding: 12px;
  margin-bottom: 24px;
  line-height: 1.5;

  div {
    margin-bottom: 12px;
  }

  & :last-child {
    margin: 0;
  }
}

.A {
  color: #60d598;
}

.B {
  color: #63a8ea;
}

.C {
  color: #f1815b;
}

.D {
  color: #ff0001;
}

.btns {
  text-align: center;
  margin-top: 10px;
}
</style>
