<template>
  <div ref="branch" v-loading="loading" class="branch card">
    <div class="section-title">成绩分布</div>
    <div class="section-sub-title">成绩分布</div>
    <div class="chart-box">
      <div class="operation">
        <div class="filter">
          <div class="label">分数段区间设置：</div>
          <div class="filter-item">
            <div class="filter-item-label">上限</div>
            <el-input
              v-model.number="search.high"
              oninput="value=value.replace(/[^\d]/g,'')"
              style="width: 72px"
              @blur="search.high = $event.target.value"
            ></el-input>
            <div class="unit">分</div>
          </div>
          <div class="filter-item">
            <div class="filter-item-label">下限</div>
            <el-input
              v-model.number="search.low"
              oninput="value=value.replace(/[^\d]/g,'')"
              style="width: 72px"
              @blur="search.low = $event.target.value"
            ></el-input>
            <div class="unit">分</div>
          </div>
          <div class="filter-item">
            <div class="filter-item-label">间隔</div>
            <el-input
              v-model.number="search.interval"
              oninput="value=value.replace(/[^\d]/g,'')"
              style="width: 72px"
              @blur="search.interval = $event.target.value"
            ></el-input>
            <div class="unit">分</div>
          </div>
          <el-button
            type="primary"
            plain
            @click="getresearchreportscoredistributionData"
            >确定</el-button
          >
        </div>
        <el-radio-group
          v-if="examType != 1"
          v-model="search.type"
          size="small"
          @change="radioChange"
        >
          <el-radio-button :label="1">人数</el-radio-button>
          <el-radio-button :label="2">占比</el-radio-button>
        </el-radio-group>
      </div>

      <barChart v-if="brachData.length" :data="barOptions" :height="500" />
      <no-data v-else></no-data>
      <div class="model-desc mt30">注：红色柱子表示我校平均分所在分数段</div>
    </div>
  </div>
</template>

<script>
import barChart from "./chart.vue";
import { getresearchreportscoredistribution } from "@/core/api/academic/teachingReport";
import { Unionscoredistribution } from "@/core/api/academic/unionReport";
import { mapState } from "vuex";
export default {
  name: "Branch",
  components: {
    barChart,
  },
  props: {
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: {
        type: 1,
        high: 100,
        low: 0,
        interval: 10,
      },
      statId: this.$route.query.id,
      unobserve: false,
      barOptions: {},
      brachData: [],
      io: {},
      loading: false,
      examType: this.$route.query.examType,
    };
  },
  computed: {
    ...mapState({
      subject: (state) => state.report.propData,
    }),
  },

  mounted() {
    this.search.high = this.topData.segmentMaximum;
    this.search.low = this.topData.segmentMinimum;
    this.search.interval = this.topData.segmentGap;
    this.getresearchreportscoredistributionData();
    this.search.type = 1;
  },

  methods: {
    radioChange() {
      this.getresearchreportscoredistributionData();
    },
    filterData() {
      let series = [
        {
          name:
            this.search.type === 1
              ? this.examType == 1
                ? "本校"
                : "人数"
              : "占比", // 不同条柱
          type: "bar",
          label: {
            position: "top",
            show: true,
            color: "inherit",
            fontSize: 9,
          },
          data: this.brachData.map((item) => {
            if (item.isGradeAverage == 1) {
              return {
                value: item.proportion,
                itemStyle: { color: "#FD8585" },
              };
            }
            return item.proportion;
          }),
          barMaxWidth: 40,
        },
      ];
      if (this.examType == 1) {
        if (this.topData.isSchoolDisplayReportUnionAverageScore == 1) {
          series = [
            ...series,
            {
              name: "整体",
              smooth: true,
              type: "line",
              data: this.brachData.map((item) => item.unionProportion),
            },
            {
              name: "上位学校",
              smooth: true,
              type: "line",
              data: this.brachData.map((item) => item.previousSchoolProportion),
            },
            {
              name: "下位学校",
              smooth: true,
              type: "line",
              data: this.brachData.map((item) => item.nextSchoolProportion),
            },
            {
              name: "学科高位",
              smooth: true,
              type: "line",
              data: this.brachData.map((item) => item.no1SchoolProportion),
            },
          ];
          if (this.brachData.length) {
            let hasPreviousSchool = this.brachData[0].hasPreviousSchool;
            let nextSchoolTotal = this.brachData[0].nextSchoolTotal;
            if (!hasPreviousSchool) {
              series = series.filter((item) => item.name !== "上位学校");
            }
            if (!nextSchoolTotal) {
              series = series.filter((item) => item.name !== "下位学校");
            }
          }
        } else {
          series = [
            ...series,
            {
              name: "上位学校",
              smooth: true,
              type: "line",
              data: this.brachData.map((item) => item.previousSchoolProportion),
            },
            {
              name: "下位学校",
              smooth: true,
              type: "line",
              data: this.brachData.map((item) => item.nextSchoolProportion),
            },
            {
              name: "学科高位",
              smooth: true,
              type: "line",
              data: this.brachData.map((item) => item.no1SchoolProportion),
            },
          ];
        }
      }
      this.barOptions = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },

          valueFormatter: function (val) {
            return Math.abs(val);
          },
        },
        legend: { itemGap: 30 },
        grid: {
          bottom: this.brachData.length > 25 ? "20%" : "5%",
          containLabel: true,
        },
        dataZoom: [
          //滚动条
          {
            show: this.brachData.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: this.brachData.length - (this.brachData.length - 25),
            xAxisIndex: [0],
            bottom: "5%",
            left: "center",
          },
        ],

        xAxis: [
          {
            type: "category",
            data: this.brachData.map((item) => item.segment),
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
              rotate: 25,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
        series: [...series],
      };
    },
    async getresearchreportscoredistributionData() {
      if (this.subject && this.search.low && this.search.high) {
        this.loading = true;
        try {
          let res;
          if (this.examType == 1) {
            res = await Unionscoredistribution({
              id: this.statId,
              subjectId: this.subject,
              viewSchoolId: this.$route.query.schoolId,
              ...this.search,
            });
          } else {
            res = await getresearchreportscoredistribution({
              id: this.statId,
              subjectId: this.subject,
              ...this.search,
            });
          }
          this.brachData = res.data.data;
          this.filterData();
          this.loading = false;
        } catch {
          this.loading = false;
        }
      } else {
        this.brachData = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.branch {
  margin-bottom: 24px;
  .title {
    font-size: 16px;
    margin-bottom: 30px;
    color: #333;
    font-weight: bold;
    span {
      color: #7a7a7a;
      font-size: 14px;
      margin-left: 24px;
      font-weight: normal;
    }
  }
  .operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .filter {
      display: flex;
      align-items: center;
      color: #5e6166;
      font-size: 14px;
      flex-wrap: wrap;
      .label {
        color: #080a09;
        font-size: 14px;
        margin-right: 24px;
      }
      .filter-item {
        display: flex;
        align-items: center;
        margin-right: 24px;
        font-size: 14px;
        color: #5e6166;
        .el-input {
          margin: 0 8px;
        }
        @media screen and (max-width: 1400px) {
          margin-bottom: 24px;
        }
      }
    }
  }
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #2474ed;
  border-color: #2474ed;
  &:hover {
    color: #fff;
  }
}
::v-deep .el-radio-button__inner {
  &:hover {
    color: #2474ed;
  }
}
</style>
