const track = [
  {
    label: "姓名",
    prop: "name",
    fixed: true,
  },
  {
    label: "任课老师",
    prop: "subjectTeacherName",
  },
  {
    label: "学科排名",
    prop: "subjectRank",
  },
  {
    label: "学科总分",
    prop: "subjectScore",
  },
  {
    label: "班级",
    prop: "classNum",
  },
  {
    label: "学科薄弱程度",
    prop: "weak",
  },
  {
    label: "校排名",
    prop: "totalRank",
  },
];
const critical = [
  {
    label: "班级",
    prop: "classNum",
  },
  {
    label: "任课老师",
    prop: "teacherName",
  },

  {
    label: "参考人数",
    prop: "students",
  },
  {
    label: "上线人数",
    prop: "onlineStudents",
  },
  {
    label: "上线率",
    prop: "onlineRate",
  },
  {
    label: "上线稳定性",
    prop: "onlineStability",
  },
];
const topWard = [
  {
    label: "班级",
    prop: "classNum",
  },
  {
    label: "任课老师",
    prop: "teacherName",
  },

  {
    label: "统计人数",
    prop: "statTotal",
  },
];
const wave = [
  {
    label: "班级",
    prop: "classNum",
  },
  {
    label: "任课老师",
    prop: "teacherName",
  },

  {
    label: "统计人数",
    prop: "students",
  },
];
export function getKnowledgePoint(examType, topData) {
  let knowledgePoint = [
    {
      label: `知识点`,
      prop: `name`,
      fixed: true,
    },
    {
      label: `题量`,
      prop: `countQuestion`,
    },
    {
      label: `满分分值	`,
      prop: `countRight`,
    },
    {
      label: `平均分`,
      prop: `averageScore`,
    },
    {
      label: `校得分率`,
      prop: `scoreRate`,
    },
    {
      label: `满分人数`,
      prop: `countRight`,
    },
    {
      label: `零分人数`,
      prop: `countZero`,
    },
    {
      label: `对应题号`,
      prop: `questionOrders`,
    },
  ];
  if (examType == 1 && topData.isSchoolDisplayReportUnionAverageScore == 1) {
    knowledgePoint.splice(3, 0, {
      label: `整体平均分`,
      prop: `unionAverageScore`,
    });
    knowledgePoint.splice(5, 0, {
      label: `整体得分率`,
      prop: `unionScoreRate`,
    });
  }
  return knowledgePoint;
}
export function getQuestionSingleStat(examType, topData) {
  let QuestionSingleStat = [
    {
      label: `题号`,
      prop: `order`,
      fixed: true,
    },
    {
      label: `满分分值	`,
      prop: `score`,
    },
    {
      label: `平均分`,
      prop: `averageScore`,
    },
    {
      label: `校得分率`,
      prop: `scoreRate`,
    },
    {
      label: `满分人数`,
      prop: `countRight`,
    },
    {
      label: `零分人数`,
      prop: `countZero`,
    },
    {
      label: `答错人数`,
      prop: `countWrong`,
    },
    {
      label: `知识点`,
      prop: `knowledgeNames`,
    },
  ];
  if (examType == 1 && topData.isSchoolDisplayReportUnionAverageScore == 1) {
    QuestionSingleStat.splice(3, 0, {
      label: `整体平均分`,
      prop: `unionAverageScore`,
    });
    QuestionSingleStat.splice(5, 0, {
      label: `整体得分率`,
      prop: `unionScoreRate`,
    });
  }
  return QuestionSingleStat;
}
export { track, critical, topWard, wave };
