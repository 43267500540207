<template>
  <div class="testAnalysis card">
    <exportBtn
      :request-func="requestFunc"
      :params="{
        id: $route.query.id,
        type: 1,
        subjectId: subject,
      }"
    >
      <div class="section-title">试卷分析</div>
    </exportBtn>
    <!-- 由字段控制 2045/5/17 -->
    <div
      v-isUnionShow="'isSchoolDisplayReportUnionAverageScore'"
      class="dashboard mt30"
    >
      <div class="dashboard-wrapper">
        <div class="data-item">
          <div class="data">
            {{ (paperQualityData.scoreRate || 0 / 1).toFixed(4) || "" }}
          </div>
          <div class="title">难度</div>
        </div>
        <div v-if="paperQualityData.reliability > 0" class="data-item">
          <div class="data">{{ paperQualityData.reliability || 0 }}</div>
          <div class="title">信度</div>
        </div>
        <div class="data-item">
          <div class="data">
            {{ (paperQualityData.discrimination || 0 / 1).toFixed(4) || "" }}
          </div>
          <div class="title">区分度</div>
        </div>
      </div>
      <div v-if="examType == 1" class="note">
        注：本模块中难度、<span
          v-if="examType == 1 && paperQualityData.reliability > 0"
          >信度、</span
        >
        区分度等试卷质量，试题质量指标均是区域整体的统计结果.
      </div>
    </div>
    <div ref="paperQuality" v-loading="loading" class="quality">
      <div class="section-sub-title">试卷命题质量</div>
      <div class="content">
        <div class="chart">
          <chart :data="qualityPieOption" :height="300" />
        </div>
        <div class="tableList">
          <div class="title">难度分布</div>
          <el-table
            ref="elTable"
            :data="researchReportPaperAnalyPaperQualityDifficultyVOs"
            :header-cell-style="{ background: '#f5f7fa' }"
            style="width: 100%"
            border
          >
            <el-table-column
              v-for="(item, index) in hardLabelList"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :width="item.width || 'auto'"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="content mt10">
        <div class="chart">
          <chart :data="qualityPieOption2" :height="300" />
        </div>
        <div class="tableList">
          <div class="title">区分度分布</div>
          <el-table
            ref="elTable2"
            :data="researchReportPaperAnalyPaperQualityDiscriminationVOs"
            :header-cell-style="{ background: '#f5f7fa' }"
            style="width: 100%"
            border
          >
            <el-table-column
              v-for="(item, index) in labelList"
              :key="index"
              show-overflow-tooltip
              :prop="item.prop"
              :label="item.label"
              :width="item.width || 'auto'"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div ref="questionquality" v-loading="loading" class="pointChart">
      <div class="section-sub-title">试题命题质量</div>
      <div class="chart-box">
        <chart :data="pointChartOption" :height="500" />

        <div class="model-desc mt30 indent">
          散点图用以说明各道小题的质量情况，并按照12象限显示。横坐标是试题难度，虚线将题目按照难度分割为3个区域：难题[0,0.4]，中等题(0.4,0.7)，简单题[0.7,1]；纵坐标是试题区分度，虚线将题目按照区分度分割为4个区域：区分度低[~,0.2)，区分度一般[0.2,0.3)，区分度良好[0.3,0.4)，区分度优秀[0.4,~)。坐标轴中每一个点代表一道题（难度，区分度）。
        </div>
      </div>
    </div>

    <div ref="knowledgeoverall">
      <div class="btn-title subject-tab">
        <div class="section-sub-title">试题诊断</div>
        <el-radio-group v-model="category" size="small" @change="radioChange">
          <el-radio-button v-if="topData.hasKnowledge == 1" :label="1"
            >知识点</el-radio-button
          >
          <el-radio-button :label="2">题目</el-radio-button>
        </el-radio-group>
      </div>
      <div class="chart-box">
        <div class="record subject-tab">
          {{ overview }}
        </div>
        <div>
          <chart v-if="barOptions" :data="barOptions" :height="500" />
          <no-data v-else></no-data>
        </div>
        <div v-if="tableData.length" class="rowForm">
          <div class="label">
            {{ category === 1 ? "选择知识点：" : "选择题目：" }}
          </div>
          <el-select
            v-model="value"
            placeholder="请选择"
            @change="selectChagne"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="
                category == 1
                  ? item.name
                  : item.point
                  ? item.questionOrder + '-' + item.point
                  : item.questionOrder
              "
              :value="
                category == 1
                  ? item.id
                  : item.point
                  ? item.point + 'point'
                  : item.questionOrder
              "
            >
            </el-option>
          </el-select>
        </div>
        <div v-if="tableData.length" class="tableList">
          <el-table
            :key="category"
            :data="tableData"
            style="width: 100%"
            border
            :header-cell-style="{ background: '#f5f7fa' }"
          >
            <div v-if="category === 1">
              <el-table-column
                v-for="(item, index) in knowledgePoint"
                :key="index + 'knowledgePoint'"
                :prop="item.prop"
                :label="item.label"
                :fixed="item.fixed"
                align="center"
              >
              </el-table-column>
            </div>
            <div v-if="category === 2">
              <template v-for="(item, index) in QuestionSingleStat" slot="">
                <el-table-column
                  v-if="
                    item.label != '知识点' ||
                    (item.label == '知识点' && topData.hasKnowledge == 1)
                  "
                  :key="index + 'QuestionSingleStat'"
                  :prop="item.prop"
                  :label="item.label"
                  :fixed="item.fixed"
                  align="center"
                >
                </el-table-column>
              </template>
            </div>
          </el-table>
        </div>
        <div v-if="category === 2 && progressData.length" class="progress">
          <progressComponent :data="progressData" :answer="answer" />
        </div>
        <div v-isUnionShow="'statClass'" class="chart-box mt10">
          <chart :data="knowledgePointBarOptions" :height="500" />
        </div>
        <div
          v-if="classNumsList.length"
          v-isUnionShow="'statClass'"
          class="classScoreList mt10"
        >
          <div class="rowForm">
            <div class="label">选择班级：</div>
            <check-component
              :source="classNumsList"
              :placeholder="'选择班级'"
              @change="getCheckClassNum"
            />
          </div>
          <el-table
            :data="classTableData"
            border
            style="wdith: 100%"
            :cell-style="cellStyle"
          >
            <el-table-column
              prop="name"
              :label="category == 1 ? '知识点' : '题号'"
              :width="classTableLabel.length > 8 ? 150 : 'auto'"
              fixed
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="schoolScoreRate"
              label="校得分率"
              :width="classTableLabel.length > 8 ? 120 : 'auto'"
              align="center"
            >
              <template slot-scope="{ row }">
                {{ row.schoolScoreRate }}
              </template>
            </el-table-column>
            <el-table-column
              v-if="
                examType == 1 &&
                topData.isSchoolDisplayReportUnionAverageScore == 1
              "
              prop="unionScoreRate"
              label="整体得分率"
              :width="classTableLabel.length > 8 ? 120 : 'auto'"
              align="center"
            >
              <template slot-scope="{ row }">
                {{ row.unionScoreRate }}
              </template>
            </el-table-column>
            <el-table-column
              prop="researchReportPaperAnalyQuestionDiagQuestionScoreRateQuestionClassVOs"
              label="班级得分率"
              align="center"
            >
              <el-table-column
                v-for="(item, index) in classTableLabel"
                :key="'class' + index"
                prop="classScoreRate"
                :label="item.classNum"
                :width="classTableLabel.length >= 8 ? 100 : 'auto'"
                align="center"
              >
                <template slot-scope="{ row }">
                  <span v-if="category === 1">
                    {{
                      row
                        .researchReportPaperAnalyQuestionDiagKnowledgeScoreRateKnowledgeClassVOs[
                        index
                      ].classScoreRate
                    }}
                  </span>
                  <span v-else>
                    {{
                      row
                        .researchReportPaperAnalyQuestionDiagQuestionScoreRateQuestionClassVOs[
                        index
                      ].classScoreRate
                    }}
                  </span>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
          <div v-if="old.length > 10" class="btns">
            <el-button type="text" @click="isExtendChange">{{
              isExtend ? "收起" : "查看更多"
            }}</el-button>
          </div>
        </div>
        <div class="model-desc mt10">
          注：标红的得分率表示班级得分率低于{{
            examType == 1 ? "整体得分率" : "我校得分率"
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chart from "./chart.vue";
import progressComponent from "./progress.vue";
import CheckComponent from "./checkComponent.vue";
import { getstatclassoption } from "@/core/api/academic/common";
import {
  getresearchreportpaperanalypaperquality,
  getresearchreportpaperanalyquestionquality,
  getresearchreportpaperanalyquestiondiagknowledgeoverall,
  getresearchreportpaperanalyquestiondiagquestionoverall,
  getpaperquestionlist,
  getpaperknowledgelist,
  exportTeachingReport,
  getresearchreportpaperanalyquestiondiagknowledgesingle,
  getresearchreportpaperanalyquestiondiagquestionsingle,
  getresearchreportpaperanalyquestiondiagknowledgescorerate,
  getresearchreportpaperanalyquestiondiagquestionscorerate,
} from "@/core/api/academic/teachingReport";
import {
  Uniongetresearchreportpaperanalypaperquality,
  Uniongetresearchreportpaperanalyquestionquality,
  Uniongetresearchreportpaperanalyquestiondiagquestionsingle,
  Uniongetresearchreportpaperanalyquestiondiagquestionscorerate,
  Uniongetresearchreportpaperanalyquestiondiagquestionoverall,
  Uniongetresearchreportpaperanalyquestiondiagknowledgesingle,
  Uniongetresearchreportpaperanalyquestiondiagknowledgescorerate,
  Uniongetresearchreportpaperanalyquestiondiagknowledgeoverall,
} from "@/core/api/academic/unionReport";
import { getKnowledgePoint, getQuestionSingleStat } from "./TableLabel";
import exportBtn from "@/views/academic/components/exportBtn.vue";
import { mapState } from "vuex";
export default {
  name: "TestAnalysis",
  components: {
    chart,
    progressComponent,
    CheckComponent,
    exportBtn,
  },
  props: {
    subject: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      requestFunc: exportTeachingReport,
      classTableData: [],
      classNumsList: [],
      qualityPieOption: {},
      qualityPieOption2: {},
      pointChartOption: {},

      value: "",
      category: 2,
      hardLabelList: [
        {
          label: "分类",
          prop: "name",
          width: 59,
        },
        {
          label: "难度系数",
          prop: "interval",
          width: 89,
        },
        {
          label: "分值",
          prop: "score",
          width: 59,
        },
        {
          label: "占比(%)",
          prop: "proportion",
          width: 89,
        },
        {
          label: "客观题",
          prop: "objectiveOrder",
          width: 150,
        },
        {
          label: "主观题",
          prop: "subjectiveOrder",
          width: "auto",
        },
      ],
      labelList: [
        {
          label: "分类",
          prop: "name",
          width: 59,
        },
        {
          label: "区分度",
          prop: "interval",
          width: 89,
        },
        {
          label: "分值",
          prop: "score",
          width: 59,
        },
        {
          label: "占比(%)",
          prop: "proportion",
          width: 89,
        },
        {
          label: "客观题",
          prop: "objectiveOrder",
          width: 150,
        },
        {
          label: "主观题",
          prop: "subjectiveOrder",
          width: "auto",
        },
      ],
      spanObj: {},
      barOptions: null,
      knowledgePointBarOptions: null,
      tableData: [],
      options: [],
      type: "",
      unobserve: false,
      statId: this.$route.query.id,
      paperQualityData: {},
      researchReportPaperAnalyPaperQualityDifficultyVOs: [],
      researchReportPaperAnalyPaperQualityDiscriminationVOs: [],
      overview: "",
      researchReportPaperAnalyQuestionDiagKnowledgeOverallKnowledgeVOs: {},
      progressData: [],
      answer: "",
      classNumChecked: "",
      paperQualityIo: null,
      questionqualityIo: {},
      knowledgeoverallIo: {},
      classTableLabel: [],
      loading: false,
      old: [],
      isExtend: false,
      examType: this.$route.query.examType,
      knowledgePoint: [],
      QuestionSingleStat: [],
    };
  },
  computed: {
    ...mapState({
      topData: (state) => state.report.topData,
    }),
  },
  watch: {
    subject() {
      this.$nextTick(() => {
        if (Number(this.subject)) {
          this.radioChange();
          this.getresearchreportpaperanalyquestionqualityData();
          this.getresearchreportpaperanalypaperqualityData();
          this.category = 2;
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getstatclassoption();
      if (Number(this.subject)) {
        this.radioChange();
        this.getresearchreportpaperanalyquestionqualityData();
        this.getresearchreportpaperanalypaperqualityData();
      }
    });
    this.knowledgePoint = getKnowledgePoint(this.examType, this.topData);
    this.QuestionSingleStat = getQuestionSingleStat(
      this.examType,
      this.topData
    );
  },
  methods: {
    isExtendChange() {
      this.isExtend = !this.isExtend;
      this.classTableData = this.isExtend ? this.old : this.old.slice(0, 10);
    },
    cellStyle(params) {
      const { row, columnIndex } = params;

      const rowData =
        this.category == 1
          ? row.researchReportPaperAnalyQuestionDiagKnowledgeScoreRateKnowledgeClassVOs
          : row.researchReportPaperAnalyQuestionDiagQuestionScoreRateQuestionClassVOs;
      if (this.examType == 1) {
        let index = columnIndex - 3;
        if (columnIndex >= 3) {
          if (
            rowData &&
            rowData[index] &&
            rowData[index].classScoreRate < row.unionScoreRate
          ) {
            return "color:#FD8585";
          }
        }
      } else {
        let index = columnIndex - 2;
        if (columnIndex >= 2) {
          if (
            rowData[index] &&
            rowData[index].classScoreRate < row.schoolScoreRate
          ) {
            return "color:#FD8585";
          }
        }
      }
    },
    async getresearchreportpaperanalypaperqualityData() {
      // 获取教研报告试卷分析 - 试卷命题质量

      if (Number(this.subject)) {
        try {
          let res;
          this.loading = false;
          if (this.$route.query.examType == 1) {
            res = await Uniongetresearchreportpaperanalypaperquality({
              id: this.statId,
              viewSchoolId: this.$route.query.schoolId,
              subjectId: this.subject,
            });
          } else {
            res = await getresearchreportpaperanalypaperquality({
              id: this.statId,
              subjectId: this.subject,
            });
          }
          this.paperQualityData = res.data.data;
          this.researchReportPaperAnalyPaperQualityDifficultyVOs =
            res.data.data.researchReportPaperAnalyPaperQualityDifficultyVOs;
          this.researchReportPaperAnalyPaperQualityDiscriminationVOs =
            res.data.data.researchReportPaperAnalyPaperQualityDiscriminationVOs;
          this.qualityPieOption = {
            tooltip: {
              trigger: "item",
              backgroundColor: "rgba(0,0,0,0.7)",
              borderColor: "rgba(0,0,0,0.7)",
              textStyle: {
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
                shadowStyle: {
                  color: "rgba(0, 0, 0, 0.1)",
                },
              },

              formatter: function ({ data, name }) {
                const { score, value } = data;
                let str =
                  "难度：" +
                  name +
                  "<br />" +
                  "占比：" +
                  score +
                  "(" +
                  value +
                  "%)";
                return str;
              },
            },
            series: [
              {
                name: "试卷难度分布",
                type: "pie",
                radius: "70%",
                data: this.researchReportPaperAnalyPaperQualityDifficultyVOs.map(
                  (item) => {
                    return {
                      value: item.proportion,
                      name: item.name,
                      score: item.score,
                    };
                  }
                ),
                label: {
                  textStyle: {
                    color: "inherit",
                    fontSize: 9,
                  },
                },
                color: ["#6EDBA7", "#FF9776", "#7AB5EF"],
                itemStyle: {
                  borderColor: "#fff",
                  borderWidth: "1",
                },
              },
            ],
          };
          this.qualityPieOption2 = {
            tooltip: {
              trigger: "item",
              backgroundColor: "rgba(0,0,0,0.7)",
              borderColor: "rgba(0,0,0,0.7)",
              textStyle: {
                color: "#fff",
              },
              formatter: function ({ data, name }) {
                const { score, value } = data;
                let str =
                  "区分度：" +
                  name +
                  "<br />" +
                  "题量占比：" +
                  score +
                  "(" +
                  value +
                  "%)";
                return str;
              },
            },
            series: [
              {
                name: "试卷区分度分布",
                type: "pie",
                radius: "70%",
                data: this.researchReportPaperAnalyPaperQualityDiscriminationVOs.map(
                  (item) => {
                    return {
                      value: item.proportion,
                      name: item.name,
                      score: item.score,
                    };
                  }
                ),
                label: {
                  textStyle: {
                    color: "inherit",
                    fontSize: 9,
                  },
                },
                color: ["#6EDBA7", "#FFD766", "#A997FB", "#FD8585"],
                itemStyle: {
                  borderColor: "#fff",
                  borderWidth: "1",
                },
              },
            ],
          };
          this.loading = false;
        } catch {
          this.loading = false;
        }
      }
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
        this.$refs.elTable2.doLayout();
      });
    },
    async radioChange() {
      this.tableData = [];
      this.options = [];
      this.knowledgePointBarOptions = null;
      this.value = "";
      if (this.category === 1) {
        this.getpaperknowledgelistData();
        this.getresearchreportpaperanalyquestiondiagknowledgeoverallData();
      } else if (this.category === 2) {
        this.getpaperquestionlistData();
        this.getresearchreportpaperanalyquestiondiagquestionoverallData();
      }
    },
    async getresearchreportpaperanalyquestionqualityData() {
      // 获取教研报告试卷分析 - 试题命题质量
      if (Number(this.subject)) {
        this.loading = true;
        try {
          let res;
          if (this.$route.query.examType == 1) {
            res = await Uniongetresearchreportpaperanalyquestionquality({
              viewSchoolId: this.$route.query.schoolId,
              id: this.statId,
              subjectId: this.subject,
            });
          } else {
            res = await getresearchreportpaperanalyquestionquality({
              id: this.statId,
              subjectId: this.subject,
            });
          }
          this.loading = false;
          this.pointChartOption = {
            tooltip: {
              backgroundColor: "rgba(0,0,0,0.7)",
              borderColor: "rgba(0,0,0,0.7)",
              textStyle: {
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
                shadowStyle: {
                  color: "rgba(0, 0, 0, 0.1)",
                },
              },
            },
            grid: {
              bottom: "10%",
              left: "10%",
            },
            xAxis: {
              name: "难度",
              nameLocation: "middle",
              nameTextStyle: {
                padding: [10, 0, 0, 0], // 四个数字分别为上右下左与原位置距离
              },
              splitLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              min: 0,
              max: 1,
              axisLabel: {
                show: true,
                textStyle: {
                  color: "rgba(0, 0, 0, 0.45)",
                },
              },
            },
            yAxis: {
              axisLine: {
                show: true,
                lineStyle: {
                  color: "rgba(0,0,0,0.45)",
                },
              },
              name: "区分度",
              nameLocation: "middle",
              nameTextStyle: {
                padding: [0, 10, 0, 0], // 四个数字分别为上右下左与原位置距离
              },
              splitLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "rgba(0, 0, 0, 0.45)",
                },
                formatter: (val) => {
                  if (val) {
                    let arr = val.toString().split("");
                    let index = 0;
                    let newArray = [];
                    while (index < arr.length) {
                      let data = arr.slice(index, (index += 5));
                      newArray.push(data.join(""));
                    }
                    let str = newArray.reduce(
                      (str, item) => str + "\n" + item,
                      ""
                    );
                    return str;
                  } else {
                    return val;
                  }
                },
              },
              max: 1,
              // min: -1,  // 2024/5/15 宜宾一中 高一下期半期联合考试 区分度有为负数情况
              min: 0,
            },
            toolbox: {
              feature: {
                dataZoom: {},
              },
            },
            series: [
              {
                symbolSize: 12,
                name: "质量情况",
                data: res.data.data.map((item) => {
                  return [item.scoreRate, item.discrimination, item.order];
                }),
                type: "scatter",
                color: ["#5B8FF9"],
                label: {
                  show: true,
                  textStyle: {
                    color: "#5B8FF9",
                  },
                  position: "right",
                  formatter: function (params) {
                    return params.data[2];
                  },
                },
                markLine: {
                  symbol: ["circle", "arrow"],
                  data: [
                    {
                      silent: false, //鼠标悬停事件  true没有，false有
                      name: "一般(0.2,0.3)",
                      lineStyle: {
                        //警戒线的样式  ，虚实  颜色
                        type: "dashed",
                        color: "#AB98FB ",
                        width: "2",
                      },
                      label: {
                        position: "middle",
                        formatter: "一般(0.2,0.3)",
                        color: "#AB98FB",
                      },
                      yAxis: 0.2, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                    },
                    {
                      silent: false, //鼠标悬停事件  true没有，false有
                      name: "良好(0.3,0.4)",
                      lineStyle: {
                        //警戒线的样式  ，虚实  颜色
                        type: "dashed",
                        color: "#F09710",
                        width: "2",
                      },
                      label: {
                        position: "middle",
                        formatter: "良好(0.3,0.4)",
                        color: "#F09710",
                      },
                      yAxis: 0.3, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                    },
                    {
                      silent: false, //鼠标悬停事件  true没有，false有
                      name: "优秀(0.4,1)",
                      lineStyle: {
                        //警戒线的样式  ，虚实  颜色
                        type: "dashed",
                        color: "#6EDBA7",
                        width: "2",
                      },
                      label: {
                        position: "middle",
                        formatter: "优秀(0.4,1)",
                        color: "#6EDBA7",
                      },
                      yAxis: 0.4, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                    },
                    {
                      silent: false, //鼠标悬停事件  true没有，false有
                      name: "中等题(0.4,0.7)",
                      lineStyle: {
                        //警戒线的样式  ，虚实  颜色
                        type: "dashed",
                        color: "#FF9474 ",
                        width: "2",
                      },
                      label: {
                        position: "end",
                        formatter: "中等题(0.4,0.7)",
                        color: "#FF9474 ",
                      },
                      xAxis: 0.4, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                    },
                    {
                      silent: false, //鼠标悬停事件  true没有，false有
                      name: "简单题(0.7,1)",
                      lineStyle: {
                        //警戒线的样式  ，虚实  颜色
                        type: "dashed",
                        color: "#63A8EC",
                        width: "2",
                      },
                      label: {
                        position: "end",
                        formatter: "简单题(0.7,1)",
                        color: "#63A8EC",
                      },
                      xAxis: 0.7, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
                    },
                  ],
                },
              },
            ],
          };
          this.getstatclassoption();
        } catch {
          this.loading = false;
        }
      }
    },
    async getresearchreportpaperanalyquestiondiagknowledgeoverallData(
      loadingInstance,
      io
    ) {
      // 获取教研报告试卷分析-试题诊断-知识点-各知识点得分率
      if (Number(this.subject)) {
        let res;
        if (this.$route.query.examType == 1) {
          res =
            await Uniongetresearchreportpaperanalyquestiondiagknowledgeoverall({
              viewSchoolId: this.$route.query.schoolId,
              id: this.statId,
              subjectId: this.subject,
            });
        } else {
          res = await getresearchreportpaperanalyquestiondiagknowledgeoverall({
            id: this.statId,
            subjectId: this.subject,
          });
        }
        this.getpaperknowledgelistData();
        this.overview = res.data.data.overview;
        this.researchReportPaperAnalyQuestionDiagKnowledgeOverallKnowledgeVOs =
          res.data.data.researchReportPaperAnalyQuestionDiagKnowledgeOverallKnowledgeVOs;
        if (
          res.data.data
            .researchReportPaperAnalyQuestionDiagKnowledgeOverallKnowledgeVOs
            .length
        ) {
          let examType = this.$route.query.examType;
          let series = [
            {
              name: examType == 1 ? "校得分率" : "得分率", // 不同条柱
              type: "bar",
              label: {
                position: "top",
                show: true,
                color: "inherit",
                fontSize: 9,
              },
              data: res.data.data.researchReportPaperAnalyQuestionDiagKnowledgeOverallKnowledgeVOs.map(
                (item) => {
                  if (item.isLow == 1) {
                    return {
                      value: item.scoreRate || "0",
                      itemStyle: { color: "#FD8585" },
                    };
                  }
                  return item.scoreRate || "0";
                }
              ),
              barMaxWidth: 40,
            },
          ];
          if (
            this.$route.query.examType == 1 &&
            this.topData.isSchoolDisplayReportUnionAverageScore == 1
          ) {
            series.push({
              type: "line",
              name: "整体得分率",
              smooth: true,
              data: [
                ...res.data.data.researchReportPaperAnalyQuestionDiagKnowledgeOverallKnowledgeVOs.map(
                  (item) => item.unionScoreRate
                ),
              ],
            });
          }
          this.barOptions = {
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgba(0,0,0,0.7)",
              borderColor: "rgba(0,0,0,0.7)",
              textStyle: {
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
                shadowStyle: {
                  color: "rgba(0, 0, 0, 0.1)",
                },
              },

              valueFormatter: function (val) {
                return Math.abs(val);
              },
            },
            legend: { itemGap: 30 },
            grid: {
              bottom:
                res.data.data
                  .researchReportPaperAnalyQuestionDiagKnowledgeOverallKnowledgeVOs
                  .length > 25
                  ? "20%"
                  : "5%",
              containLabel: true,
            },
            dataZoom: [
              //滚动条
              {
                show:
                  res.data.data
                    .researchReportPaperAnalyQuestionDiagKnowledgeOverallKnowledgeVOs
                    .length > 25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue:
                  res.data.data
                    .researchReportPaperAnalyQuestionDiagKnowledgeOverallKnowledgeVOs
                    .length -
                  (res.data.data
                    .researchReportPaperAnalyQuestionDiagKnowledgeOverallKnowledgeVOs
                    .length -
                    25),
                xAxisIndex: [0],
                bottom: "5%",
                left: "center",
              },
            ],
            xAxis: [
              {
                type: "category",
                data: res.data.data.researchReportPaperAnalyQuestionDiagKnowledgeOverallKnowledgeVOs.map(
                  (item) => item.name || "-"
                ),
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "rgba(0, 0, 0, 0.45)",
                  },
                  formatter: (val) => {
                    let arr = val.split("");
                    let index = 0;
                    let newArray = [];
                    while (index < arr.length) {
                      let data = arr.slice(index, (index += 5));
                      newArray.push(data.join(""));
                    }
                    let str = newArray.reduce(
                      (str, item) => str + "\n" + item,
                      ""
                    );
                    return str;
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
            series: [...series],
          };
        } else {
          this.barOptions = null;
        }
      }
      if (io) {
        this.$nextTick(() => {
          loadingInstance.close();
          this.knowledgeoverallIo = io;
          io.unobserve(this.$refs.knowledgeoverall);
        });
      }
    },
    async getresearchreportpaperanalyquestiondiagquestionoverallData() {
      // 各个小题得分
      this.loading = true;
      try {
        let res;
        if (this.$route.query.examType == 1) {
          res =
            await Uniongetresearchreportpaperanalyquestiondiagquestionoverall({
              id: this.statId,
              viewSchoolId: this.$route.query.schoolId,
              subjectId: this.subject,
            });
        } else {
          res = await getresearchreportpaperanalyquestiondiagquestionoverall({
            id: this.statId,
            subjectId: this.subject,
          });
        }
        this.overview = res.data.data.overview;
        let data =
          res.data.data
            .researchReportPaperAnalyQuestionDiagQuestionOverallQuestionVOs;
        if (data.length) {
          let series = [
            {
              name: this.$route.query.examType == 1 ? "校得分率" : "得分率", // 不同条柱
              type: "bar",
              label: {
                position: "top",
                show: true,
                color: "inherit",
                fontSize: 9,
              },
              data: res.data.data.researchReportPaperAnalyQuestionDiagQuestionOverallQuestionVOs.map(
                (item) => {
                  if (item.isLow === 1) {
                    return {
                      value: item.scoreRate || "0",
                      itemStyle: { color: "#FD8585" },
                    };
                  }
                  return item.scoreRate || "0";
                }
              ),
              barMaxWidth: 40,
            },
          ];
          if (
            this.$route.query.examType == 1 &&
            this.topData.isSchoolDisplayReportUnionAverageScore == 1
          ) {
            series = [
              ...series,
              {
                type: "line",
                name: "整体得分率",
                smooth: true,
                data: [...data.map((item) => item.unionScoreRate)],
              },
            ];
          }
          this.barOptions = {
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgba(0,0,0,0.7)",
              borderColor: "rgba(0,0,0,0.7)",
              textStyle: {
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
                shadowStyle: {
                  color: "rgba(0, 0, 0, 0.1)",
                },
              },

              valueFormatter: function (val) {
                return Math.abs(val);
              },
            },
            legend: { itemGap: 30 },
            grid: {
              bottom: data.length > 25 ? "20%" : "5%",
              containLabel: true,
            },
            dataZoom: [
              //滚动条
              {
                show: data.length > 25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: data.length - (data.length - 25),
                xAxisIndex: [0],
                bottom: "5%",
                left: "center",
              },
            ],
            xAxis: [
              {
                type: "category",
                data: data.map((item) => item.order),
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "rgba(0, 0, 0, 0.45)",
                  },
                  formatter: (val) => {
                    let arr = val.split("");
                    let index = 0;
                    let newArray = [];
                    while (index < arr.length) {
                      let data = arr.slice(index, (index += 5));
                      newArray.push(data.join(""));
                    }
                    let str = newArray.reduce(
                      (str, item) => str + "\n" + item,
                      ""
                    );
                    return str;
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
            series: [...series],
          };
        } else {
          this.barOptions = null;
        }
      } catch {
        this.loading = false;
      }
    },
    async getpaperquestionlistData() {
      const res = await getpaperquestionlist({
        id: this.statId,
        subjectId: this.subject,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.options = res.data.data;
      if (res.data.data.length) {
        this.value = res.data.data[0].questionOrder;
      }
      this.getresearchreportpaperanalyquestiondiagquestionsingleData();
      // this.getresearchreportpaperanalyquestiondiagquestionscorerateData();
    },
    async getpaperknowledgelistData() {
      const res = await getpaperknowledgelist({
        id: this.statId,
        subjectId: this.subject,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.options = [];

      if (res.data.data.length) {
        this.options = res.data.data;
        this.value = res.data.data.length ? res.data.data[0].id : null;
        this.getresearchreportpaperanalyquestiondiagknowledgesingleData();
        this.getresearchreportpaperanalyquestiondiagknowledgescorerateData();
      }
    },
    selectChagne() {
      if (this.category === 1) {
        this.getresearchreportpaperanalyquestiondiagknowledgesingleData();
      } else if (this.category === 2) {
        this.getresearchreportpaperanalyquestiondiagquestionsingleData();
      }
    },
    async getresearchreportpaperanalyquestiondiagknowledgesingleData() {
      // 单个知识点得分情况
      let examType = this.$route.query.examType;
      try {
        let res;
        let series = [];
        let key = "";
        if (examType == 1) {
          key = "researchReportPaperAnalyQuestionDiagKnowledgeSingleClassVOs";
          res =
            await Uniongetresearchreportpaperanalyquestiondiagknowledgesingle({
              id: this.statId,
              subjectId: this.subject,
              knowledgeId: this.value,
              viewSchoolId: this.$route.query.schoolId,
            });
          this.tableData = [
            res.data.data
              .researchReportPaperAnalyQuestionDiagKnowledgeSingleStatVO,
          ];
          let data =
            res.data.data
              .researchReportPaperAnalyQuestionDiagKnowledgeSingleClassVOs;
          let resData = res.data.data;
          series = [
            {
              name: "班级得分率", // 不同条柱
              type: "bar",
              label: {
                position: "top",
                show: true,
                color: "inherit",
                fontSize: 9,
              },
              data: data.map((item) => {
                if (item.isLow == 1) {
                  return {
                    value: item.classScoreRate || "0",
                    itemStyle: { color: "#FD8585" },
                  };
                }
                return item.classScoreRate || "0";
              }),
              barMaxWidth: 40,
            },
            {
              name: "学校得分率",
              type: "line",
              smooth: true,
              color: "#FFA486",
              data: data.map(() => resData.schoolScoreRate || 0),
            },
          ];
          if (
            this.examType == 1 &&
            this.topData.isSchoolDisplayReportUnionAverageScore == 1
          ) {
            series.push({
              type: "line",
              name: "整体得分率",
              color: "#6EDBA7",
              smooth: true,
              data: data.map(() => resData.unionScoreRate || 0),
            });
          }
        } else {
          key = "researchReportPaperAnalyQuestionDiagKnowledgeSingleClassVOs";
          res = await getresearchreportpaperanalyquestiondiagknowledgesingle({
            id: this.statId,
            subjectId: this.subject,
            knowledgeId: this.value,
          });
          this.tableData = [
            res.data.data
              .researchReportPaperAnalyQuestionDiagKnowledgeSingleStatVO,
          ];

          series = [
            {
              name: "得分率", // 不同条柱
              type: "bar",
              label: {
                position: "top",
                show: true,
                color: "inherit",
                fontSize: 9,
              },
              data: res.data.data.researchReportPaperAnalyQuestionDiagKnowledgeSingleClassVOs.map(
                (item) => {
                  if (item.isLow == 1) {
                    return {
                      value: item.classScoreRate || "0",
                      itemStyle: { color: "#FD8585" },
                    };
                  }
                  return item.classScoreRate || "0";
                }
              ),
              barMaxWidth: 40,
            },
          ];
        }

        if (res.data.data[key].length) {
          this.knowledgePointBarOptions = {
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgba(0,0,0,0.7)",
              borderColor: "rgba(0,0,0,0.7)",
              textStyle: {
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
                shadowStyle: {
                  color: "rgba(0, 0, 0, 0.1)",
                },
              },

              valueFormatter: function (val) {
                return Math.abs(val);
              },
            },
            legend: { itemGap: 30 },

            grid: {
              bottom: res.data.data[key].length > 25 ? "20%" : "5%",
              containLabel: true,
            },
            dataZoom: [
              //滚动条
              {
                show: res.data.data[key].length > 25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue:
                  res.data.data[key].length - (res.data.data[key].length - 25),
                xAxisIndex: [0],
                bottom: "5%",
                left: "center",
              },
            ],
            xAxis: [
              {
                type: "category",
                data: res.data.data[key].map((item) => item.classNum || "-"),
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "rgba(0, 0, 0, 0.45)",
                  },
                  formatter: (val) => {
                    let arr = val.split("");
                    let index = 0;
                    let newArray = [];
                    while (index < arr.length) {
                      let data = arr.slice(index, (index += 5));
                      newArray.push(data.join(""));
                    }
                    let str = newArray.reduce(
                      (str, item) => str + "\n" + item,
                      ""
                    );
                    return str;
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
            series: [...series],
          };
        } else {
          this.knowledgePointBarOptions = null;
        }
      } catch {
        this.knowledgePointBarOptions = null;
      }
    },
    async getresearchreportpaperanalyquestiondiagquestionsingleData() {
      if (this.value) {
        let field = "questionOrder";
        let value = this.value;
        if (this.value.indexOf("point") > -1) {
          field = "point";
          let stringArr = this.value.split("point");
          value = stringArr[0];
        }
        let itemData = this.options.find((item) => item[field] == value);
        try {
          this.loading = true;
          let res;
          if (this.$route.query.examType == 1) {
            res =
              await Uniongetresearchreportpaperanalyquestiondiagquestionsingle({
                id: this.statId,
                subjectId: this.subject,
                order: itemData.questionOrder,
                viewSchoolId: this.$route.query.schoolId,
                point: itemData.point,
              });
          } else {
            res = await getresearchreportpaperanalyquestiondiagquestionsingle({
              id: this.statId,
              subjectId: this.subject,
              order: itemData.questionOrder,
              point: itemData.point,
            });
          }

          this.loading = false;
          this.progressData =
            res.data.data.researchReportPaperAnalyQuestionDiagQuestionSingleAnswerVOs;
          this.answer = res.data.data.rightAnswer;
          this.tableData = [
            ...res.data.data
              .researchReportPaperAnalyQuestionDiagQuestionSingleStatVOS,
          ];
          if (
            res.data.data
              .researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs.length
          ) {
            let series = [
              {
                name: "班级得分率", // 不同条柱
                type: "bar",
                label: {
                  position: "top",
                  show: true,
                  color: "inherit",
                  fontSize: 9,
                },
                data: res.data.data.researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs.map(
                  (item) => {
                    if (item.isLow == 1) {
                      return {
                        value: item.classScoreRate || "0",
                        itemStyle: { color: "#FD8585" },
                      };
                    }
                    return item.classScoreRate || "0";
                  }
                ),
                barMaxWidth: 40,
              },
              {
                name: "学校得分率",
                type: "line",
                smooth: true,
                color: "#FFA486",
                data: res.data.data.researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs.map(
                  () => res.data.data.schoolScoreRate || 0
                ),
              },
            ];
            if (
              this.examType == 1 &&
              this.topData.isSchoolDisplayReportUnionAverageScore == 1
            ) {
              series = [
                ...series,
                {
                  type: "line",
                  name: "整体得分率",
                  color: "#6EDBA7",
                  smooth: true,
                  data: res.data.data.researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs.map(
                    () => res.data.data.unionScoreRate || 0
                  ),
                },
              ];
            }
            this.knowledgePointBarOptions = {
              tooltip: {
                trigger: "axis",
                backgroundColor: "rgba(0,0,0,0.7)",
                borderColor: "rgba(0,0,0,0.7)",
                textStyle: {
                  color: "#fff",
                },
                axisPointer: {
                  type: "shadow",
                  shadowStyle: {
                    color: "rgba(0, 0, 0, 0.1)",
                  },
                },

                valueFormatter: function (val) {
                  return Math.abs(val);
                },
              },
              legend: { itemGap: 30 },
              grid: {
                bottom:
                  res.data.data
                    .researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs
                    .length > 25
                    ? "15%"
                    : "5%",
                containLabel: true,
              },
              dataZoom: [
                //滚动条
                {
                  show:
                    res.data.data
                      .researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs
                      .length > 25,
                  type: "slider",
                  realtime: true,
                  startValue: 0, // 重点
                  // 重点-dataX.length表示x轴数据长度
                  endValue:
                    res.data.data
                      .researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs
                      .length -
                    (res.data.data
                      .researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs
                      .length -
                      25),
                  xAxisIndex: [0],
                  bottom: "5%",
                  left: "center",
                },
              ],
              xAxis: [
                {
                  type: "category",
                  data: res.data.data.researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs.map(
                    (item) => item.classNum
                  ),
                  axisTick: {
                    show: false,
                  },
                  axisLabel: {
                    show: true,

                    formatter: (val) => {
                      if (val.length > 5) {
                        let arr = val.split("");
                        let index = 0;
                        let newArray = [];
                        while (index < arr.length) {
                          let data = arr.slice(index, (index += 5));
                          newArray.push(data.join(""));
                        }
                        let str = newArray.reduce(
                          (str, item) => (str != "" ? str + "\n" + item : item),
                          ""
                        );
                        return str;
                      } else {
                        return val;
                      }
                    },
                  },
                },
              ],
              yAxis: [
                {
                  type: "value",
                },
              ],
              color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
              series: [...series],
            };
          } else {
            this.knowledgePointBarOptions = null;
            this.progressData = [];
          }
        } catch {
          this.loading = false;
        }
      } else {
        this.knowledgePointBarOptions = null;
        this.progressData = [];
      }
    },
    async getstatclassoption() {
      const res = await getstatclassoption({
        id: this.statId,
        requestPage: 2,
        viewSchoolId: this.examType == 1 ? this.$route.query.schoolId : null,
      });
      this.classNumsList = res.data.data;
      this.classNumChecked = res.data.data.map((item) => item.name);
    },
    async getresearchreportpaperanalyquestiondiagknowledgescorerateData() {
      let res;
      if (this.$route.query.examType == 1) {
        res =
          await Uniongetresearchreportpaperanalyquestiondiagknowledgescorerate({
            subjectId: this.subject,
            classNums: this.classNumChecked,
            viewSchoolId: this.$route.query.schoolId,
            id: this.statId,
          });
      } else {
        res = await getresearchreportpaperanalyquestiondiagknowledgescorerate({
          subjectId: this.subject,
          classNums: this.classNumChecked,
          id: this.statId,
        });
      }
      let data =
        res.data.data
          .researchReportPaperAnalyQuestionDiagKnowledgeScoreRateKnowledgeVOs;
      this.classTableData = data.slice(0, 10);
      this.loading = false;
      this.isExtend = false;
      this.old = data;
      this.classTableLabel =
        data.length &&
        data[0]
          .researchReportPaperAnalyQuestionDiagKnowledgeScoreRateKnowledgeClassVOs;
    },
    async getresearchreportpaperanalyquestiondiagquestionscorerateData() {
      this.loading = true;
      try {
        let res;
        if (this.$route.query.examType == 1) {
          res =
            await Uniongetresearchreportpaperanalyquestiondiagquestionscorerate(
              {
                subjectId: this.subject,
                classNums: this.classNumChecked.toString(),
                viewSchoolId: this.$route.query.schoolId,
                id: this.statId,
              }
            );
        } else {
          res = await getresearchreportpaperanalyquestiondiagquestionscorerate({
            subjectId: this.subject,
            classNums: this.classNumChecked.toString(),
            id: this.statId,
          });
        }
        let data =
          res.data.data
            .researchReportPaperAnalyQuestionDiagQuestionScoreRateQuestionVOs;
        this.classTableData = data.slice(0, 10);
        this.isExtend = false;
        this.old = data;
        this.classTableLabel =
          data.length &&
          data[0]
            .researchReportPaperAnalyQuestionDiagQuestionScoreRateQuestionClassVOs;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    getCheckClassNum(val) {
      this.classNumChecked = val.map((item) => item.name);
      if (this.category === 2) {
        this.getresearchreportpaperanalyquestiondiagquestionscorerateData();
      } else if (this.category === 1) {
        this.getresearchreportpaperanalyquestiondiagknowledgescorerateData();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.testAnalysis {
  font-size: 16px;

  .dashboard {
    background-color: #f4f9fe;

    box-sizing: border-box;
    padding: 20px 20%;
    margin-bottom: 24px;

    .dashboard-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .note {
      font-size: 14px;
      text-align: center;
      margin-top: 18px;
      color: #6f6f70;
    }

    .data-item {
      flex: 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;

      .data {
        font-size: 24px;
        margin-bottom: 10px;
        color: #3f87f4;
        text-align: center;
      }

      .title {
        color: #6f6f70;
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .quality {
    .title {
      color: #333333;
      font-weight: 500;
      font-weight: bold;
      margin-bottom: 24px;
    }

    .content {
      display: flex;
      align-items: center;
      padding: 20px;
      border: 1px solid #e9e9e9;
      border-radius: 4px;

      .chart {
        width: 40%;
      }

      .tableList {
        width: 600px;
        box-sizing: border-box;

        ::v-deep .hover-row {
          .el-table__cell {
            background-color: #fff5d7;
          }
        }

        ::v-deep .el-table {
          font-size: 12px !important;
        }

        ::v-deep .el-table .cell {
          padding: 0 4px;
        }

        .title {
          font-size: 14px;
          color: #333333;
          font-weight: 500;
          margin-bottom: 14px;
        }
      }
    }
  }

  .pointChart {
    margin-top: 24px;

    .title {
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      margin-bottom: 24px;
    }
  }

  .tag {
    font-size: 14px;
    border: 1px dashed #2474ed;
    color: #737677;
    background-color: #f2f8fe;
    box-sizing: border-box;
    padding: 12px;
    margin-bottom: 24px;
    line-height: 1.5;

    div {
      margin-bottom: 12px;
    }

    & :last-child {
      margin: 0;
    }
  }

  .btn-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .record {
    font-size: 14px;
    color: #737677;
    line-height: 1.5;
    box-sizing: border-box;
    padding: 20px 12px;
    background-color: #f2f8fe;
  }

  .rowForm {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .label {
      color: #080a09;
      font-size: 14px;
      margin-right: 8px;
    }
  }

  .tableList {
    margin-bottom: 24px;

    ::v-deep tbody tr:hover > td {
      background-color: #fff5d7 !important;
    }
  }

  .progress {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 24px;
    padding: 20px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
  }
}

.btns {
  text-align: center;
  margin: 10px 0;
}
</style>
