<template>
  <div ref="DevelopAnalysis" v-loading="loading" class="card container">
    <div class="section-title">发展性分析</div>
    <exam-transfer
      :subject-id="subject"
      :stat-id="statId"
      :exam-arr="examArr"
      style="margin-bottom: 10px"
      :request-page="2"
      @submit="getExamId"
    />
    <!-- <div class="filter">
      <div class="section-sub-title">班级历次跟踪</div>
      <div class="filter-item">
        <div class="label">选择班级：</div>
        <check-component
          :source="classNumsList"
          :limit="limit"
          @change="getCheckClassNum"
        />
      </div>
    </div> -->
    <!-- 
    <div class="tableList">
      <el-table
        ref="elTable"
        :data="tableData"
        border
        :span-method="objectSpanMethod"
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          width="150"
          label="考试"
          fixed
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="classNum"
          fixed
          label="班级"
          width="100"
          align="center"
        >
        </el-table-column>

        <el-table-column
          v-for="(item, index) in onlines"
          :key="('onlines', index)"
          :label="item.name"
          align="center"
        >
          <el-table-column prop="total" label="人数" width="100" align="center">
            <template slot-scope="{ row }">{{
              row.onlines[index].total
            }}</template>
          </el-table-column>
          <el-table-column
            prop="proportion"
            label="占比"
            width="100"
            align="center"
          >
            <template slot-scope="{ row }">{{
              row.onlines[index].proportion
            }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in ratios"
          :key="'ratios' + index"
          :label="item.name"
          align="center"
        >
          <el-table-column prop="total" label="人数" width="100" align="center">
            <template slot-scope="{ row }">{{
              row.ratios[index].total
            }}</template>
          </el-table-column>
          <el-table-column
            prop="proportion"
            label="占比"
            width="100"
            align="center"
          >
            <template slot-scope="{ row }">{{
              row.ratios[index].proportion
            }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in topSections"
          :key="'topSections' + index"
          :label="item.name"
          align="center"
        >
          <el-table-column label="人数" prop="total" width="100" align="center">
            <template slot-scope="{ row }">{{
              row.topSections[index].total
            }}</template>
          </el-table-column>
          <el-table-column
            prop="proportion"
            label="占比"
            width="100"
            align="center"
          >
            <template slot-scope="{ row }">{{
              row.topSections[index].proportion
            }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="lowestScore" label="最低分" align="center">
        </el-table-column>
        <el-table-column prop="rangeScore" label="全距" align="center">
        </el-table-column>
        <el-table-column
          prop="deviationFromMean"
          label="离均差（校）"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="overAverageRate"
          label="超均率（校）"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="standardDeviation" label="标准差" align="center">
        </el-table-column>
        <el-table-column
          prop="coefficientOfVariation"
          label="差异系数"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div> -->
    <div class="chart-box">
      <div class="operation">
        <div style="display: flex">
          <div class="selects-item" style="margin-right: 10px">
            <div class="label">选择班级：</div>
            <check-component
              :source="classNumsList"
              :placeholder="'选择班级'"
              @change="getCheckClassNum"
            />
          </div>
          <div
            v-if="
              (examType == 1 && [3, 4].includes(category)) ||
              (examType != 1 && category != 1 && category != 5)
            "
            class="selects-item"
          >
            <div class="label">选择指标：</div>
            <el-select
              v-model="type"
              style="width: 174px"
              placeholder="请选择"
              @change="selectChange"
            >
              <el-option
                v-for="item in options"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </div>
          <div v-else-if="category == 5" class="selects-item">
            <div class="label">选择指标：</div>
            <el-select
              v-model="otherType"
              style="width: 174px"
              placeholder="请选择"
              @change="otherSelectChange"
            >
              <el-option
                v-for="(item, index) in otherOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <el-radio-group v-model="category" size="small" @change="radioChange">
          <el-radio-button :label="1">超均率（校）</el-radio-button>
          <el-radio-button v-if="examType == 1" :label="2"
            >超均率（整体）</el-radio-button
          >
          <el-radio-button v-else :label="2">上线情况</el-radio-button>
          <el-radio-button :label="3">比率</el-radio-button>
          <el-radio-button :label="4">前N名</el-radio-button>
          <el-radio-button :label="5">其他指标</el-radio-button>
        </el-radio-group>
      </div>
      <div v-if="chartOptions" class="chart">
        <bar-chart :key="chartKey" :data="chartOptions" :height="500" />
      </div>
      <no-data v-else></no-data>
      <div class="model-desc mt10">
        <div>折线图——班级历次考试指标值</div>
        <div>
          1.
          同色折线不同考试节点对比：最近一次考试与历次考试进行对比，用于查看班级考试指标的变化趋势。
        </div>
        <div>
          2.
          同一考试节点不同颜色值对比：每次考试各班级间指标的差距。可选择同比、环比及周期性对比的角度进行发展性的分析。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import barChart from "./chart.vue";

import examTransfer from "../../components/examTransfer.vue";
import { getresearchreportdevelopanalysis } from "@/core/api/academic/teachingReport";
import { Uniongetresearchreportdevelopanalysis } from "@/core/api/academic/unionReport";
import {
  getstatclassoption,
  getstatcomparelist,
} from "@/core/api/academic/common";
import checkComponent from "./checkComponent.vue";
import { examTypeOptions } from "@/core/util/constdata";
import { dataMethod } from "./methods";

export default {
  name: "DevelopAnalysis",
  components: {
    examTransfer,
    barChart,
    checkComponent,
  },

  props: {
    subject: {
      type: [String, Number, null],
      default: null,
    },
  },

  data() {
    return {
      dialogVisible: false,
      tableData: [],
      category: 1,
      classNumsList: [],
      examTypeOptions: examTypeOptions,
      topSections: [],
      onlines: [],
      ratios: [],
      options: [],
      statId: this.$route.query.id,
      examType: this.$route.query.examType,
      spanObj: "",
      old: [],
      type: "超均率（校）",
      field: "",
      chartOptions: {},
      otherOptions: [
        {
          label: "最高分",
          value: "highestScore",
        },
        {
          label: "最低分",
          value: "lowestScore",
        },
        {
          label: "标准差",
          value: "standardDeviation",
        },
      ],
      otherType: "highestScore",
      examList: [],
      checkedExam: null,
      examName: "",
      classNums: "",
      settingName: "",
      examArr: [],
      classArr: [],
      loading: false,
      limit: 5,
      chartKey: Math.random(),
    };
  },

  mounted() {
    this.$nextTick(() => {
      if (this.subject) {
        this.getConditions();
      }
    });
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    async getConditions() {
      const res = await getstatclassoption({
        id: this.statId,
        requestPage: 2,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.classNums = this.classNumsList = res.data.data.map(
        (item) => item.name
      );
      this.getresearchreportdevelopanalysisData();
    },
    async getstatcompare() {
      this.loading = true;
      const res = await getstatcomparelist({
        statId: this.statId,
        examType: this.examType,
        classNum: this.classNums.toString(),
      });
      this.examList = res.data.data;
      // this.$refs.transferComponent.clearAll();
      this.loading = false;
    },
    otherSelectChange() {
      this.chartOptions = null;
      if (this.examArr.length) {
        let reverseArr = [...this.examArr].reverse();
        const xAxis = reverseArr.map(
          (item) => item.name + "\n" + item.examDate
        );
        const data = reverseArr.flatMap((item) => item.classes);
        let name = this.otherOptions.find(
          (item) => item.value === this.otherType
        ).label;
        const series = this.classNums.map((item) => {
          const sameClass = data.filter((it) => it.classNum === item);
          return {
            name: item + name,
            type: "line",
            label: {
              show: true,
              textStyle: {
                color: "inherit",
                fontSize: 9,
              },
              position: "top",
            },
            data: sameClass.map((item) => item[this.otherType]),
          };
        });

        this.chartOptions = {
          grid: {
            bottom: xAxis.length > 25 ? "30%" : "15%",
          },
          dataZoom: [
            //滚动条
            {
              show: xAxis.length > 25,
              type: "slider",
              realtime: true,
              startValue: 0, // 重点
              // 重点-dataX.length表示x轴数据长度
              endValue: xAxis.length - (xAxis.length - 25),
              xAxisIndex: [0],
              left: "center",
            },
          ],
          xAxis: [
            {
              type: "category",
              data: xAxis,
              axisTick: {
                show: false,
              },
              axisLabel: {
                rotate: 20,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisTick: {
                show: false,
              },
            },
          ],
          series: [...series],
        };
        this.chartKey = Math.random();
      }
    },
    getExamId(val) {
      this.category = 1;
      this.checkedExam = val.toString();
      this.getresearchreportdevelopanalysisData();
    },
    selectChange() {
      this.setOptions();
    },
    setOptions() {
      this.chartOptions = null;
      if (this.examArr.length) {
        let reverseArr = [...this.examArr].reverse();
        const xAxis = reverseArr.map(
          (item) => item.name + "\n" + item.examDate
        );
        const data = reverseArr.flatMap((item) => item.classes);
        const series = this.classNums.map((item) => {
          const a = data.filter((it) => item == it.classNum);
          const fin = a.map((i) => {
            const itemData = i[this.field].find((n) => n.name === this.type);
            return this.field == "topSections"
              ? itemData.total
              : itemData.proportion;
          });
          return {
            name: item + this.type,
            type: "line",

            label: {
              show: true,
              textStyle: {
                color: "inherit",
                fontSize: 9,
              },
              position: "top",
            },
            data: fin,
          };
        });
        this.chartOptions = {
          legend: {
            itemGap: 20,
            type: "scroll",
            orient: "horizontal",
          },

          grid: {
            bottom: xAxis.length > 25 ? "30%" : "15%",
          },
          dataZoom: [
            //滚动条
            {
              show: xAxis.length > 25,
              type: "slider",
              realtime: true,
              startValue: 0, // 重点
              // 重点-dataX.length表示x轴数据长度
              endValue: xAxis.length - (xAxis.length - 25),
              xAxisIndex: [0],
              left: "center",
            },
          ],
          xAxis: [
            {
              type: "category",
              data: xAxis,
              axisTick: {
                show: false,
              },
              axisLabel: {
                rotate: 20,
              },
            },
          ],
          series: series,
          yAxis: [
            {
              type: "value",
              axisTick: {
                show: false,
              },
            },
          ],
        };
        this.chartKey = Math.random();
      }
    },
    objectSpanMethod({ column, columnIndex, rowIndex }) {
      if (columnIndex === 0) {
        let _row = this.spanObj[column.property][rowIndex];
        let _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    async getresearchreportdevelopanalysisData() {
      this.chartOptions = null;
      let res;
      if (this.$route.query.examType == 1) {
        res = await Uniongetresearchreportdevelopanalysis({
          id: this.statId,
          classNums: this.classNums.toString(),
          contrastStatId: this.checkedExam,
          subjectId: this.subject,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getresearchreportdevelopanalysis({
          id: this.statId,
          classNums: this.classNums.toString(),
          contrastStatId: this.checkedExam,
          subjectId: this.subject,
        });
      }

      let arr = res.data.data.map((item) => {
        return item.classes.map((it) => {
          return {
            name: item.name,
            id: item.id,
            ...it,
          };
        });
      });
      this.tableData = arr.flat();
      this.spanObj = dataMethod(this.tableData, ["name"]);
      if (res.data.data.length) {
        this.topSections =
          this.tableData.length && this.tableData[0].topSections;
        this.onlines = this.tableData.length && this.tableData[0].onlines;
        this.ratios = this.tableData.length && this.tableData[0].ratios;
      }
      this.examArr = res.data.data;
      this.classArr =
        res.data.data.length &&
        res.data.data[0].classes.map((item) => item.classNum);
      this.averageScoreFilter();
    },
    filterData(data) {
      let tableData = data.map((item) => {
        return item.competitiveVOS.map((it) => {
          return { name: item.name, ...it };
        });
      });
      return tableData.flat();
    },
    getCheckClassNum(val) {
      this.classNums = val;
      this.radioChange();
    },
    averageScoreFilter() {
      this.chartOptions = null;

      let reverseArr = [...this.examArr].reverse();

      const xAxis = reverseArr.map((item) => item.name + "\n" + item.examDate);
      const data = reverseArr.flatMap((item) => item.classes);
      const series = this.classNums.map((item) => {
        const sameClass = data.filter((it) => it.classNum === item);
        return {
          name:
            item +
            (this.examType == 1 && this.category == 2
              ? "超均率（整体）"
              : "超均率（校）"),
          type: "line",
          label: {
            show: true,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
            position: "top",
          },
          data: sameClass.map((item) =>
            this.examType == 1 && this.category == 2
              ? item.overAverageRateUnion
              : item.overAverageRate
          ),
        };
      });
      this.chartOptions = {
        legend: {
          itemGap: 20,
          type: "scroll",
          orient: "horizontal",
        },
        grid: {
          bottom: xAxis.length > 25 ? "30%" : "15%",
        },
        dataZoom: [
          //滚动条
          {
            show: xAxis.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: xAxis.length - (xAxis.length - 25),
            xAxisIndex: [0],
            bottom: "5%",
            left: "center",
          },
        ],

        xAxis: [
          {
            type: "category",
            data: xAxis,
            axisTick: {
              show: false,
            },
            axisLabel: {
              rotate: 20,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false,
            },
          },
        ],
        series: series,
      };
      this.chartKey = Math.random();
    },
    setting() {
      this.dialogVisible = true;
      this.getstatcompare();
    },
    radioChange() {
      this.type = null;
      switch (this.category) {
        case 1:
          this.type = "超均率（校）";
          this.getresearchreportdevelopanalysisData();
          break;
        case 2:
          if (this.examType == 1) {
            this.type = "超均率（整体）";
            this.getresearchreportdevelopanalysisData();
          } else {
            this.field = "onlines";
            if (this.tableData.length) {
              this.type = this.tableData[0].onlines[0].name;
              this.options = this.tableData[0].onlines;
            }
            this.selectChange();
          }
          break;
        case 3:
          this.field = "ratios";
          if (this.tableData.length) {
            this.type = this.tableData[0].ratios[0].name;
            this.options = this.tableData[0].ratios;
          }
          this.selectChange();
          break;
        case 4:
          this.field = "topSections";
          if (this.tableData.length) {
            this.type = this.tableData[0].topSections[0].name;
            this.options = this.tableData[0].topSections;
          }
          this.selectChange();
          break;
        case 5:
          // this.otherType = "highestScore";
          this.otherSelectChange();
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .tag {
    font-size: 14px;
    border: 1px dashed #2474ed;
    color: #737677;
    background-color: #f2f8fe;
    box-sizing: border-box;
    padding: 12px;
    margin-bottom: 24px;

    div {
      margin-bottom: 12px;
    }

    & :last-child {
      margin: 0;
    }
  }

  .filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 10px;

    .filter-item {
      display: flex;
      align-items: center;

      span {
        margin: 0 8px;
      }

      .label {
        color: #080a09;
        font-size: 14px;
        margin-right: 8px;
      }
    }
  }

  .section-sub-title {
    font-size: 16px;
    color: #333333;
    font-weight: 550;
    margin-bottom: 24px;
  }

  .tableList {
    margin-bottom: 32px;
    ::v-deep .hover-row {
      .el-table__cell {
        background-color: #fff5d7 !important;
      }
    }
  }

  .operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.selects-item {
  display: flex;
  align-items: center;
  margin-right: 25px;

  .label {
    font-size: 14px;
    color: #080a09;
    margin-right: 8px;
  }
}
</style>
