<template>
  <div ref="ClassCompetitive" v-loading="loading" class="card container">
    <exportBtn
      :request-func="requestFunc"
      :params="{
        id: $route.query.id,
        type: 2,
        subjectId: subject,
      }"
    >
      <div class="section-title">班级竞争力</div>
    </exportBtn>
    <div class="model-desc mt30">
      <div v-if="examType == 1">
        <div>{{ textObj.overview1 }}</div>
        <div>{{ textObj.overview2 }}</div>
        <div>{{ textObj.overview3 }}</div>
      </div>
      <div v-else v-html="textObj"></div>
    </div>
    <div class="filter">
      <div class="section-sub-title">班级指标对比</div>
      <div class="row">
        <div class="label">选择班级：</div>
        <check-component
          :placeholder="'选择班级'"
          :source="classNumsList"
          @change="handleChange"
        />
      </div>
    </div>
    <div class="tableList">
      <el-table
        ref="elTable"
        :data="tableData"
        style="width: 100%"
        @sort-change="soltHandle"
      >
        <el-table-column
          key="classNum"
          prop="classNum"
          label="班级"
          fixed
          width="80"
          align="center"
        >
        </el-table-column>
        <el-table-column
          key="teacherName"
          prop="teacherName"
          label="任课教师"
          fixed
          width="120"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>
              {{
                row.classNum &&
                ["本校", "整体", "整体高位班级"].includes(row.classNum)
                  ? "-"
                  : row.teacherName
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          key="statTotal"
          prop="statTotal"
          label="统计人数"
          fixed
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="averageScore"
          label="平均分"
          sortable="custom"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          v-for="(item, index) in onlines"
          :key="index"
          :label="item.name"
          align="center"
        >
          <el-table-column
            v-if="examType == 1"
            :sort-by="`onlines,${index},ep`"
            prop="ep"
            label="有效分"
            width="100"
            sortable="custom"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.onlines">{{ row.onlines[index].ep }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :sort-by="`onlines,${index},total`"
            prop="total"
            label="人数"
            width="100"
            sortable="custom"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.onlines">{{ row.onlines[index].total }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :sort-by="`onlines,${index},proportion`"
            prop="proportion"
            label="占比（%）"
            width="140"
            sortable="custom"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.onlines">{{
                row.onlines[index].proportion
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="examType == 1 && topData.isSchoolDisplayReportClassRank == 1"
            :sort-by="`onlines,${index},rank`"
            prop="rank"
            label="排名"
            width="100"
            sortable="custom"
            align="center"
          >
            <template slot-scope="{ row }">
              {{
                ["本校", "整体"].includes(row.classNum)
                  ? "-"
                  : row.onlines[index].rank
              }}</template
            >
          </el-table-column>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in ratios"
          :key="'ratios' + index"
          :label="item.name"
          align="center"
        >
          <el-table-column
            label="人数"
            :sort-by="`ratios,${index},total`"
            prop="total"
            sortable="custom"
            width="100"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.ratios">{{ row.ratios[index].total }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :sort-by="`ratios,${index},proportion`"
            prop="proportion"
            label="占比（%）"
            sortable="custom"
            width="140"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.ratios">{{ row.ratios[index].proportion }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="examType == 1 && topData.isSchoolDisplayReportClassRank == 1"
            :sort-by="`ratios,${index},rank`"
            prop="rank"
            label="排名"
            width="100"
            sortable="custom"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.ratios">{{
                ["本校", "整体", ""].includes(row.classNum)
                  ? "-"
                  : row.ratios[index].rank
              }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in topSections"
          :key="'topSections' + index"
          :label="item.name"
          align="center"
        >
          <el-table-column
            label="人数"
            :sort-by="`topSections,${index},total`"
            prop="total"
            sortable="custom"
            width="100"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.topSections">{{
                row.topSections[index].total
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :sort-by="`topSections,${index},proportion`"
            prop="proportion"
            label="占比（%）"
            width="140"
            sortable="custom"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.topSections">{{
                row.topSections[index].proportion
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="examType == 1 && topData.isSchoolDisplayReportClassRank == 1"
            :sort-by="`topSections,${index},rank`"
            prop="rank"
            label="排名"
            width="100"
            sortable="custom"
            align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="row.topSections">{{
                ["本校", "整体", ""].includes(row.classNum)
                  ? "-"
                  : row.topSections[index].rank
              }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="highestScore"
          label="最高分"
          width="100px"
          sortable="custom"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="lowestScore"
          label="最低分"
          width="100px"
          sortable="custom"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="rangeScore"
          label="全距"
          width="100px"
          sortable="custom"
          align="center"
        >
          <template slot-scope="{ row }">{{
            ["学科高位班级", "整体", ""].includes(row.classNum)
              ? "-"
              : row.rangeScore
          }}</template>
        </el-table-column>
        <el-table-column
          prop="median"
          label="中位数"
          width="100px"
          sortable="custom"
          align="center"
        >
          <template slot-scope="{ row }">{{
            ["学科高位班级", "整体", ""].includes(row.classNum)
              ? "-"
              : row.median
          }}</template>
        </el-table-column>
        <el-table-column
          prop="deviationFromMean"
          :label="`离均差（${examType == 1 ? '整体' : '校'}）`"
          width="150px"
          sortable="custom"
          align="center"
        >
          <template slot-scope="{ row }">{{
            ["学科高位班级", "整体", ""].includes(row.classNum)
              ? "-"
              : examType == 1
              ? row.deviationFromMeanUnion
              : row.deviationFromMean
          }}</template>
        </el-table-column>
        <el-table-column
          prop="overAverageRate"
          :label="`超均率（${examType == 1 ? '整体' : '校'}）`"
          width="150px"
          sortable="custom"
          align="center"
        >
          <template slot-scope="{ row }">{{
            ["学科高位班级", "整体"].includes(row.classNum)
              ? "-"
              : examType == 1
              ? row.overAverageRateUnion
              : row.overAverageRate
          }}</template>
        </el-table-column>
        <el-table-column
          prop="standardDeviation"
          label="标准差"
          width="150px"
          sortable="custom"
          align="center"
        >
          <template slot-scope="{ row }">{{
            ["学科高位班级", "整体", ""].includes(row.classNum)
              ? "-"
              : row.standardDeviation
          }}</template>
        </el-table-column>
        <el-table-column
          prop="coefficientOfVariation"
          label="差异系数"
          width="150px"
          sortable="custom"
          align="center"
        >
          <template slot-scope="{ row }">{{
            ["学科高位班级", "整体", ""].includes(row.classNum)
              ? "-"
              : row.coefficientOfVariation
          }}</template>
        </el-table-column>
      </el-table>
      <div v-if="classData.length > 10" class="btns">
        <el-button type="text" @click="extendChange">{{
          isExtend ? "收起" : "查看更多"
        }}</el-button>
      </div>
    </div>
    <div class="chart-box">
      <div class="operation">
        <div class="selects">
          <div v-if="category !== 1 && category !== 5" class="selects-item">
            <div class="label">选择指标：</div>
            <el-select
              v-model="type"
              style="width: 174px"
              placeholder="请选择"
              @change="selectChange"
            >
              <el-option
                v-for="item in options"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </div>
          <div v-if="category === 5" class="selects-item">
            <div class="label">选择指标：</div>
            <el-select
              v-model="otherType"
              style="width: 174px"
              placeholder="请选择"
              @change="otherSelectChange"
            >
              <el-option
                v-for="item in otherOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <el-radio-group v-model="category" size="small" @change="radioChange">
          <el-radio-button :label="1">平均分</el-radio-button>
          <el-radio-button v-if="onlines.length" :label="2"
            >上线情况</el-radio-button
          >
          <el-radio-button v-if="topSections.length" :label="3"
            >比率</el-radio-button
          >
          <el-radio-button v-if="ratios.length" :label="4"
            >前N名</el-radio-button
          >
          <el-radio-button :label="5">其他指标</el-radio-button>
        </el-radio-group>
      </div>
      <div class="chart">
        <bar-chart :data="chartOptions" :height="500" />
      </div>
    </div>
    <template v-isUnionShow="'statClass'">
      <div class="section-sub-title">成绩分布</div>
      <div class="chart-box">
        <div class="operation-brach">
          <div v-if="search.type === 0" class="filter">
            <div class="label">分数段区间设置：</div>
            <div class="filter-item">
              <div class="filter-item-label">上限</div>
              <el-input
                v-model.number="search.high"
                oninput="value=value.replace(/[^\d]/g,'')"
                style="width: 72px"
                @blur="search.high = $event.target.value"
              ></el-input>
              <div class="unit">分</div>
            </div>
            <div class="filter-item">
              <div class="filter-item-label">下限</div>
              <el-input
                v-model.number="search.low"
                oninput="value=value.replace(/[^\d]/g,'')"
                style="width: 72px"
                @blur="search.low = $event.target.value"
              ></el-input>
              <div class="unit">分</div>
            </div>
            <div class="filter-item">
              <div class="filter-item-label">间隔</div>
              <el-input
                v-model.number="search.interval"
                oninput="value=value.replace(/[^\d]/g,'')"
                style="width: 72px"
                @blur="search.interval = $event.target.value"
              ></el-input>
              <div class="unit">分</div>
            </div>
            <el-button type="primary" plain @click="confirm">确定</el-button>
          </div>
          <div v-else class="filter">
            <div class="label">排名区间设置：</div>
            <div class="filter-item" style="margin-right: 5px">
              <el-input
                v-model.number="search.high"
                style="width: 72px"
                oninput="value=value.replace(/[^\d]/g,'')"
                @blur="search.high = $event.target.value"
              ></el-input>
              <div class="unit">名</div>
            </div>
            <div class="filter-item">
              <div class="filter-item-label">—</div>
              <el-input
                v-model.number="search.low"
                oninput="value=value.replace(/[^\d]/g,'')"
                style="width: 72px"
                @blur="search.low = $event.target.value"
              ></el-input>
              <div class="unit">名</div>
            </div>
            <div class="filter-item">
              <div class="filter-item-label">间隔</div>
              <el-input
                v-model.number="search.interval"
                oninput="value=value.replace(/[^\d]/g,'')"
                style="width: 72px"
                @blur="search.interval = $event.target.value"
              ></el-input>
              <div class="unit">名</div>
            </div>
            <el-button type="primary" plain @click="confirm">确定</el-button>
          </div>
          <el-radio-group
            v-if="examType != 1"
            v-model="search.type"
            :label="1"
            size="small"
            @change="rankChange"
          >
            <el-radio-button :label="0">分数段</el-radio-button>
            <el-radio-button :label="1">排名</el-radio-button>
          </el-radio-group>
        </div>

        <heat-bar
          v-if="heatData"
          :source="heatData"
          :tooltip="toolTip"
          :head-height="350"
          :show-visual-map="true"
          x-aixs-reverse="top"
          :y-inverse="true"
        />
        <no-data v-else></no-data>
        <div class="model-desc mt10 indent">
          横坐标为班级，按照班级名称排序，纵坐标为分数（或排名）区间，色块数值为该班级该分数（或排名）区间内的人数，人数越多则颜色越深，如出现空白区域，则该班级该分数（或排名）区域内没有人数，如分数（或排名）中段区域连续出现多个空白，则代表该班级该分数（或排名）范围内学生断档。
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import heatBar from "../../charts/heat.vue";
import barChart from "./chart.vue";
import CheckComponent from "./checkComponent.vue";

import {
  getresearchreportclasscompetitionindicator,
  getresearchreportclasscompetitionscoredistribution,
  exportTeachingReport,
} from "@/core/api/academic/teachingReport";
import {
  Uniongetresearchreportclasscompetitionindicator,
  Uniongetresearchreportclasscompetitionscoredistribution,
} from "@/core/api/academic/unionReport";
import { getstatclassoption } from "@/core/api/academic/common";
import exportBtn from "@/views/academic/components/exportBtn.vue";
export default {
  name: "ClassCompetitive",
  components: {
    heatBar,
    barChart,
    CheckComponent,
    exportBtn,
  },
  props: {
    topData: {
      default: () => {},
      type: Object,
    },
    subject: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      requestFunc: exportTeachingReport,
      subjectId: "0",
      io: {},
      tableData: [],
      topSections: [],
      onlines: [],
      ratios: [],
      search: {
        high: 100,
        low: 0,
        type: 0,
        interval: 10,
      },
      category: 1,
      label: "",
      options: [
        {
          label: "最高分",
          value: "",
        },
        {
          label: "最低分",
        },
        {
          label: "全距",
        },
        {
          label: "中位数",
        },
        {
          label: "离均差（校）",
        },
        {
          label: "超均差（校）",
        },
        {
          label: "标准差",
        },
        {
          label: "异差系数",
        },
      ],
      type: "",
      classNumsList: [],
      chartData: [],
      classNums: "",
      statId: this.$route.query.id,
      tabs: [],
      chartOptions: {},
      classData: [],
      schoolData: [],
      categoryField: "",
      loading: false,
      otherOptions: [
        {
          label: "最高分",
          value: "highestScore",
        },
        {
          label: "最低分",
          value: "lowestScore",
        },
        {
          label: "全距",
          value: "rangeScore",
        },
        {
          label: "中位数",
          value: "median",
        },
        {
          label: "离均差（校）",
          value: "deviationFromMean",
        },
        {
          label: "超均率（校）",
          value: "overAverageRate",
        },
        {
          label: "标准差",
          value: "standardDeviation",
        },
        {
          label: "差异系数",
          value: "coefficientOfVariation",
        },
      ],
      otherType: "",
      textObj: {},
      heatData: {},
      totalData: null,
      old: [],
      isExtend: false,
      examType: this.$route.query.examType,
      toolTip: {
        formatter(params) {
          return (
            "人数<br />" + params.marker + params.name + ":" + params.data[2]
          );
        },
      },
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.elTable.doLayout();
    });
    this.search.type = 0;
    this.search.high = this.topData.segmentMaximum;
    this.search.low = this.topData.segmentMinimum;
    this.search.interval = this.topData.segmentGap;
    this.getConditionData();
    this.category = 1;
    this.subjectId = this.subject;
  },
  methods: {
    extendChange() {
      this.isExtend = !this.isExtend;
      let data = this.isExtend ? this.classData : this.classData.slice(0, 10);
      this.tableData = JSON.parse(JSON.stringify(data));
      this.tableData = [...this.tableData, this.schoolData];
    },
    rankChange() {
      if (this.search.type == 0) {
        this.search.high = this.topData.segmentMaximum;
        this.search.low = this.topData.segmentMinimum;
        this.search.interval = this.topData.segmentGap;
      } else {
        this.search.high = this.topData.rankMaximum || null;
        this.search.low = this.topData.rankMinimum || null;
        this.search.interval = this.topData.rankGap || null;
      }
      this.classscoredistributionData();
    },
    confirm() {
      this.classscoredistributionData();
    },
    otherSelectChange() {
      let data = this.classData.map((item) => item[this.otherType]);
      let xAxis = this.classData.map((item) => item.classNum);
      let item = this.otherOptions.find(
        (item) => item.value === this.otherType
      );
      let series = [
        {
          name: item.label, // 不同条柱
          type: "bar",
          itemStyle: {
            normal: {
              color: function (params) {
                if (params.value > 0) {
                  return "#7AB5EF";
                } else {
                  return "#FD8585";
                }
              },
            },
          },
          data: data.map((it) => {
            return {
              value: it,
              label: {
                show: true,
                textStyle: {
                  color: "inherit",
                  fontSize: 9,
                },
                position: it >= 0 ? "top" : "bottom",
                formatter: ({ value }) => {
                  return Math.abs(value);
                },
              },
            };
          }),
          barMaxWidth: 40,
        },
        {
          name: "全校" + item.label,
          type: "line",
          label: {
            position: "top",
            show: false,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
            formatter: ({ value }) => {
              return Math.abs(value);
            },
          },
          data: new Array(xAxis.length).fill(
            this.schoolData[this.otherType],
            0,
            xAxis.length
          ),
        },
      ];

      if (
        this.otherType == "overAverageRate" ||
        this.otherType == "deviationFromMean"
      ) {
        series = [
          {
            name: item.label, // 不同条柱
            type: "bar",
            itemStyle: {
              normal: {
                color: function (params) {
                  if (params.value > 0) {
                    return "#7AB5EF";
                  } else {
                    return "#FD8585";
                  }
                },
              },
            },
            data: data.map((it) => {
              return {
                value: it,
                label: {
                  show: true,
                  textStyle: {
                    color: "inherit",
                    fontSize: 9,
                  },
                  position: it >= 0 ? "top" : "bottom",
                  formatter: ({ value }) => {
                    return value;
                  },
                },
              };
            }),
            barMaxWidth: 40,
          },
        ];
      }
      if (
        this.examType == 1 &&
        this.topData.isSchoolDisplayReportUnionAverageScore == 1
      ) {
        series = [
          ...series,
          {
            name: "整体" + item.label,
            type: "line",
            color: "#FF5233",
            data: new Array(xAxis.length).fill(
              this.totalData[this.otherType],
              0,
              xAxis.length
            ),
          },
        ];
      }
      this.chartOptions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#000",
            },
          },
          valueFormatter: function (val) {
            return Math.abs(val);
          },
        },
        legend: { itemGap: 30 },
        xAxis: [
          {
            type: "category",
            data: this.classData.map((item) => item.classNum),
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false,
            },
          },
        ],
        grid: {
          bottom: this.classData.length > 25 ? "20%" : "5%",
        },
        dataZoom: [
          //滚动条
          {
            show: this.classData.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: this.classData.length - (this.classData.length - 25),
            xAxisIndex: [0],
            bottom: "5%",
            left: "center",
          },
        ],
        series: series,
      };
    },
    selectChange() {
      let xAxis = this.classData.map((item) => item.classNum);
      let school = this.schoolData[this.categoryField].find(
        (item) => item.name === this.type
      );

      let classData = JSON.parse(JSON.stringify(this.classData));
      const arr = classData.flatMap((item) => item[this.categoryField]);
      const data = arr.filter((item) => item.name == this.type);
      let series = [
        {
          name: this.type, // 不同条柱
          type: "bar",
          label: {
            show: true,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
            position: "top",
          },
          data: data.map((item) => item.proportion),

          barMaxWidth: 40,
        },
        {
          name: `全校${this.type}`,
          type: "line",
          data: new Array(xAxis.length).fill(
            school.proportion,
            0,
            xAxis.length
          ),
          color: "#FF5233",
        },
      ];
      if (
        this.examType == 1 &&
        this.topData.isSchoolDisplayReportUnionAverageScore == 1
      ) {
        let total = this.totalData[this.categoryField].find(
          (item) => item.name === this.type
        );
        series = [
          ...series,
          {
            name: `整体${this.type}`,
            type: "line",
            data: new Array(xAxis.length).fill(
              total.proportion,
              0,
              xAxis.length
            ),
            color: "#FF5233",
          },
        ];
      }
      this.chartOptions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#000",
            },
          },
        },
        legend: { itemGap: 30 },
        xAxis: [
          {
            type: "category",
            data: classData.map((item) => item.classNum),
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false,
            },
          },
        ],
        grid: {
          bottom: classData.length > 25 ? "25%" : "5%",
        },
        dataZoom: [
          //滚动条
          {
            show: classData.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: classData.length - (classData.length - 25),
            xAxisIndex: [0],
            bottom: "5%",
            left: "center",
          },
        ],
        series: [...series],
      };
    },
    handleChange(val) {
      let arr = val.map((item) => item.name);
      this.classNums = arr.toString();
      this.getschoolreportclasscompetitiveData();
    },
    radioChange() {
      switch (this.category) {
        case 1:
          this.averageScoreFilter();
          break;
        case 2:
          this.onlinesFilter();
          break;
        case 3:
          this.ratesFilter();
          break;
        case 4:
          this.topFilter();
          break;
        case 5:
          this.otherFilter();
          break;
      }
    },
    otherFilter() {
      this.otherType = "highestScore";
      this.otherSelectChange();
    },
    averageScoreFilter() {
      let classData = this.classData.sort(
        (a, b) => Number(a.classNum) - Number(b.classNum)
      );
      let xAxis = classData.map((item) => item.classNum);
      let series = [
        {
          name: "平均分", // 不同条柱
          type: "bar",
          label: {
            show: true,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
            position: "top",
          },
          data: classData.map((item) => item.averageScore),
          barMaxWidth: 40,
        },
        {
          name: "全校平均分",
          type: "line",
          label: {
            position: "top",
            show: false,
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
          },
          data: new Array(xAxis.length).fill(
            this.schoolData.averageScore,
            0,
            xAxis.length
          ),
          color: "#FF5233",
        },
      ];
      if (
        this.examType == 1 &&
        this.topData.isSchoolDisplayReportUnionAverageScore == 1
      ) {
        series = [
          ...series,
          {
            name: "整体",
            type: "line",
            label: {
              position: "top",
              show: false,
              textStyle: {
                color: "inherit",
                fontSize: 9,
              },
            },
            data: new Array(xAxis.length).fill(
              this.totalData.averageScore,
              0,
              xAxis.length
            ),
            color: "#E8684A",
          },
        ];
      }
      this.chartOptions = {
        legend: { itemGap: 30 },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#000",
            },
          },
        },

        grid: {
          bottom: classData.length > 25 ? "25%" : "5%",
        },
        dataZoom: [
          //滚动条
          {
            show: classData.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: classData.length - (classData.length - 25),
            xAxisIndex: [0],
            bottom: "5%",
            left: "center",
          },
        ],
        xAxis: [
          {
            type: "category",
            data: classData.map((item) => item.classNum),
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false,
            },
          },
        ],
        series: [...series],
      };
    },
    setTableData() {
      if (this.examType == 1) {
        this.tableData = [...this.tableData, this.schoolData, this.totalData];
      } else {
        this.tableData = [...this.tableData, this.schoolData];
      }
    },
    soltHandle(column) {
      let fieldName = column.prop;
      let sortingType = column.order;
      let { sortBy } = column.column;
      if (!sortBy) {
        if (sortingType == "ascending") {
          // 正序
          this.classData.sort((a, b) => b[fieldName] - a[fieldName]);
          this.tableData = this.isExtend
            ? this.classData
            : this.classData.slice(0, 10);
          this.setTableData();
        } else if (sortingType == "descending") {
          this.classData.sort((a, b) => a[fieldName] - b[fieldName]);
          this.tableData = this.isExtend
            ? this.classData
            : this.classData.slice(0, 10);
          this.setTableData();
        }
      } else if (sortBy.indexOf(",") != -1) {
        let [arrName, index, arrFieldName] = sortBy.split(",");
        if (sortingType == "ascending") {
          this.classData.sort((a, b) => {
            return (
              b[arrName][Number(index)][arrFieldName] -
              a[arrName][Number(index)][arrFieldName]
            );
          });
          this.tableData = this.isExtend
            ? this.classData
            : this.classData.slice(0, 10);
          this.setTableData();
        } else if (sortingType == "descending") {
          this.classData.sort((a, b) => {
            return (
              a[arrName][Number(index)][arrFieldName] -
              b[arrName][Number(index)][arrFieldName]
            );
          });
          this.tableData = this.isExtend
            ? this.classData
            : this.classData.slice(0, 10);
          this.setTableData();
        } else {
          this.tableData = this.isExtend
            ? this.classData
            : this.classData.slice(0, 10);
          this.setTableData();
        }
      }
    },
    ratesFilter() {
      this.options = this.ratios;
      this.type = this.ratios[0].name;
      this.categoryField = "ratios";
      this.selectChange();
    },
    topFilter() {
      this.options = this.topSections;
      this.type = this.topSections[0].name;
      this.categoryField = "topSections";
      this.selectChange();
    },
    onlinesFilter() {
      this.options = this.onlines;
      this.type = this.onlines[0].name;
      this.categoryField = "onlines";
      this.selectChange();
    },
    async getConditionData() {
      if (this.subject) {
        this.loading = true;
        const res = await this.getstatclassoptionData();
        this.classNumsList = res.data.data;
        this.classNums =
          this.classNumsList.length &&
          this.classNumsList.map((item) => item.name).toString();
        this.getschoolreportclasscompetitiveData();
        this.classscoredistributionData();
      }
      this.loading = false;
    },
    async classscoredistributionData() {
      // 获取班级成绩分布
      if (this.search.high) {
        let res;
        if (this.$route.query.schoolId == 1) {
          res = await Uniongetresearchreportclasscompetitionscoredistribution({
            subjectId: this.subject,
            viewSchoolId: this.$route.query.schoolId,
            id: this.statId,
            ...this.search,
          });
        } else {
          res = await getresearchreportclasscompetitionscoredistribution({
            subjectId: this.subject,
            id: this.statId,
            ...this.search,
          });
        }

        let data = res.data.data.map((item, index) => {
          let arr =
            item.researchReportClassCompetitionScoreDistributionClassSegmentVOs.reverse();
          return arr.map((it, ind) => {
            return [index, ind, it.total];
          });
        });
        const totalData = data.flat().map((item) => item[2]);
        let ClassSegmentVOs = [
          ...res.data.data[0]
            .researchReportClassCompetitionScoreDistributionClassSegmentVOs,
        ];
        if (res.data.data.length) {
          this.heatData = {
            xAxis: res.data.data.map((item) => item.name),
            yAxis: ClassSegmentVOs.map((item) => item.name),
            data: data.flat(),
            max: Math.max(...totalData),
            min: Math.min(...totalData),
          };
        } else {
          this.heatData = null;
        }
      } else if (!this.search.high && this.search.type == 1) {
        this.$alert("请输入排名区间", "", {
          confirmButtonText: "确定",
          callback: () => {},
        });
        this.heatData = {
          xAxis: [],
          yAxis: [],
          data: [],
          max: 0,
          min: 0,
        };
      }
    },
    async getschoolreportclasscompetitiveData() {
      // 获取班级竞争力数据
      this.category = 1;
      let res;
      if (this.examType == 1) {
        res = await Uniongetresearchreportclasscompetitionindicator({
          id: this.statId,
          subjectId: this.subject,
          classNums: this.classNums,
          viewSchoolId: this.$route.query.schoolId,
        });
        this.textObj = {
          overview1: res.data.data.overview1,
          overview2: res.data.data.overview2,
          overview3: res.data.data.overview3,
        };
      } else {
        res = await getresearchreportclasscompetitionindicator({
          id: this.statId,
          subjectId: this.subject,
          classNums: this.classNums,
        });
        this.textObj = res.data.data.overview;
      }

      this.schoolData =
        res.data.data.researchReportClassCompetitionIndicatorClassVOs.find(
          (item) => item.classNum == (this.examType == 1 ? "本校" : "我校")
        );
      this.classData =
        res.data.data.researchReportClassCompetitionIndicatorClassVOs.filter(
          (item) => !["我校", "本校", "整体"].includes(item.classNum)
        );
      if (
        this.examType == 1 &&
        this.topData.isSchoolDisplayReportUnionAverageScore == 1
      ) {
        this.totalData =
          res.data.data.researchReportClassCompetitionIndicatorClassVOs.find(
            (item) => item.classNum == "整体"
          );
      }
      let data = this.classData.slice(0, 10);
      this.tableData = JSON.parse(JSON.stringify(data));

      this.tableData = [...this.tableData, this.schoolData, this.totalData];

      if (this.tableData.length) {
        this.topSections = this.tableData[0].topSections;
        this.onlines = this.tableData[0].onlines;
        this.ratios = this.tableData[0].ratios;
        this.averageScoreFilter();
      }
      this.$nextTick(() => {
        this.$refs.elTable.doLayout();
      });
    },
    getstatclassoptionData() {
      //校级报告-班级竞争力-查询条件(科目和班级)
      return getstatclassoption({
        id: this.statId,
        requestPage: 2,
        viewSchoolId: this.$route.query.schoolId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .tag {
    font-size: 14px;
    border: 1px dashed #2474ed;
    color: #737677;
    background-color: #f2f8fe;
    box-sizing: border-box;
    padding: 12px;
    margin-bottom: 24px;
    line-height: 1.5;

    div {
      margin-bottom: 12px;
    }

    & :last-child {
      margin: 0;
    }
  }

  .tableList {
    margin-bottom: 32px;
    width: 100%;

    ::v-deep .hover-row {
      .el-table__cell {
        background-color: #fff5d7 !important;
      }
    }
  }

  .operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .selects {
      display: flex;
      align-items: center;

      &-item {
        display: flex;
        align-items: center;
        margin-right: 25px;

        .label {
          font-size: 14px;
          color: #080a09;
          margin-right: 8px;
        }
      }
    }
  }
}

.operation-brach {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .filter {
    display: flex;
    align-items: center;
    color: #5e6166;
    font-size: 14px;
    flex-wrap: wrap;
    justify-content: space-between;
    font-weight: 500;

    .label {
      color: #080a09;
      font-size: 14px;
      margin-right: 24px;
    }

    .filter-item {
      display: flex;
      align-items: center;
      margin-right: 24px;
      font-size: 14px;
      color: #5e6166;

      .el-input {
        margin: 0 8px;
      }

      @media screen and (max-width: 1400px) {
        margin-bottom: 24px;
      }
    }
  }
}

::v-deep .el-tabs__nav-wrap::after {
  display: none;
}

::v-deep .el-tabs__active-bar {
  display: none;
}

::v-deep .el-tabs__item {
  padding: 0 24px !important;
  height: 32px;
  line-height: 32px;
  margin: 0 18px;
  box-sizing: border-box;
}

::v-deep .el-tabs {
  .is-active {
    border: 1px solid #3f87f4;
    border-radius: 16px;
    background-color: #f2faff;
  }
}

::v-deep .el-icon-arrow-left {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}

::v-deep .el-icon-arrow-right {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}

.filter {
  margin: 28px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: normal;

  .row {
    display: flex;
    align-items: center;

    .label {
      font-size: 14px;
      color: #080a09;
    }
  }
}

::v-deep .el-select-dropdown {
  display: none !important;
  opacity: 0 !important;
}

::v-deep .el-popper {
  display: none !important;
  opacity: 0 !important;
}

.btns {
  margin-top: 10px;
  text-align: center;
}

::v-deep .el-table__fixed {
  height: calc(100% - 16px) !important;
}
</style>
