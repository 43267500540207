<template>
  <div ref="bar" class="bar" :style="{ height: height + 'px' }"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "Bar",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    height: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      charts: {},
    };
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          this.initChart(val);
        });
      },
    },
  },

  methods: {
    initChart(val) {
      if (this.$refs.bar) {
        if (this.charts) {
          this.charts = echarts.init(this.$refs.bar, null, {
            devicePixelRatio: 2.5,
          });
        }

        // this.$refs.bar.setAttribute("_echarts_instance_", "");
        this.charts.setOption(
          {
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                crossStyle: {
                  color: "#000",
                },
              },
            },
            color: ["#7AB5EF", "#FF9776", "#6EDBA7"],
            ...val,
          },
          true
        );
        setTimeout(() => {
          window.onresize = () => {
            this.charts.resize();
          };
        }, 100);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bar {
  width: 100%;
}
</style>
