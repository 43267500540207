<template>
  <div ref="FocusOnStudent" v-loading="loading" class="card focusOnStudent">
    <div class="section-title">重点关注学生</div>
    <div class="radio mt20">
      <el-radio-group v-model="radio" size="mini" @change="radioChange">
        <el-radio-button v-if="showCriticalStudent()" :label="2"
          >临界生</el-radio-button
        >
        <el-radio-button v-if="showStudentRank()" :label="3"
          >波动生</el-radio-button
        >
        <el-radio-button v-if="showStudentRank()" :label="4"
          >拔尖生</el-radio-button
        >
        <el-radio-button v-if="showStudentRank()" :label="5"
          >后进生</el-radio-button
        >
        <el-radio-button :label="1">跟踪生</el-radio-button>
      </el-radio-group>
    </div>
    <div class="chart-box">
      <div v-if="radio === 2" class="filter">
        <div class="filter-item">
          <div class="label">选择段次：</div>
          <el-select
            v-model="search.onlineId"
            style="width: 174px"
            placeholder="请选择"
            @change="validateCritical"
          >
            <el-option
              v-for="item in levelOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          <div class="label">临界分数：</div>
          <el-input
            v-model="search.interval"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @keyup.enter="validateCritical"
            @blur="search.interval = $event.target.value"
          ></el-input>
        </div>
        <el-button
          type="primary"
          style="margin-left: 10px"
          plain
          @click="validateCritical"
          >确定</el-button
        >
      </div>
      <div v-if="radio === 3" class="filter">
        <div class="filter-item">
          <div class="label">校排名前：</div>
          <el-input
            v-model="wave.upTop"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="wave.upTop = $event.target.value"
          ></el-input>
          <span>名中，与选中考试对比进步最大的前</span>
          <el-input
            v-model="wave.upMax"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="wave.upMax = $event.target.value"
          ></el-input>
          <span>名。</span>
          <el-select
            v-model="wave.upStatId"
            style="width: 400px"
            clearable
            placeholder="请选择"
            @change="validateWave"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <el-button type="primary" plain @click="validateWave">确定</el-button>
      </div>
      <div v-if="radio === 4" class="filter">
        <div class="filter-item">
          <div class="label">校排名前：</div>
          <el-input
            v-model.number="topOrBackward"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="topOrBackward = $event.target.value"
          ></el-input>
          <el-button
            type="primary"
            plain
            style="margin-left: 15px"
            @click="validToporbackward"
            >确定</el-button
          >
        </div>
      </div>
      <div v-if="radio === 5" class="filter">
        <div class="filter-item">
          <div class="label">校排名后：</div>
          <el-input
            v-model.number="topOrBackward"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="topOrBackward = $event.target.value"
          ></el-input>
          <el-button
            type="primary"
            plain
            style="margin-left: 15px"
            @click="validToporbackward"
            >确定</el-button
          >
        </div>
      </div>
      <bar-chart
        v-if="chartOptions"
        style="margin-bottom: 24px"
        :data="chartOptions"
        :height="500"
      />
      <no-data v-else style="margin: 10px 0"></no-data>
      <div v-if="radio === 3" class="filter">
        <div class="filter-item">
          <div class="label">校排名前：</div>
          <el-input
            v-model="backWave.downTop"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="backWave.downTop = $event.target.value"
          ></el-input>
          <span>名中，与选中考试对比退步最大的前</span>
          <el-input
            v-model="backWave.downMax"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            @blur="backWave.downMax = $event.target.value"
          ></el-input>
          <span>名。</span>
          <el-select
            v-model="backWave.downStatId"
            style="width: 400px"
            clearable
            placeholder="请选择"
            @change="validateBackWava"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <el-button type="primary" plain @click="validateBackWava"
          >确定</el-button
        >
      </div>
      <template v-if="radio === 3">
        <bar-chart
          v-if="waveBackChartOptions"
          :data="waveBackChartOptions"
          style="margin-bottom: 24px"
          :height="500"
        />
        <no-data v-else style="margin: 10px 0"></no-data>
      </template>
      <exportBtn
        :request-func="requestFunc"
        :params="{
          id: $route.query.id,
          type: 4,
          subjectId: subject,
          topOrBackward: topOrBackward,
          ...backWave,
          ...wave,
          criticalInterval: search.interval,
        }"
      >
        <div v-if="radio !== 1 && tableData.length" class="filter">
          <div class="filter-item">
            <div class="label">选择班级：</div>
            <check-component
              :source="classNumsList"
              :placeholder="'选择班级'"
              @change="getCheckClassNum"
            />
          </div>
        </div>
      </exportBtn>

      <div v-if="tableData.length" class="tableList">
        <el-table
          ref="tableData"
          v-loading="tableLoading"
          :data="tableData"
          :header-cell-style="{ background: '#f5f7fa' }"
          style="width: 100%"
          border
        >
          <template v-if="radio === 1">
            <el-table-column
              v-for="(item, index) in track"
              :key="index + 'track'"
              :prop="item.prop"
              :fixed="item.fixed"
              :label="item.label"
              align="center"
            >
              <template slot-scope="{ row }">
                <span>{{ row[item.prop] || "-" }}</span>
              </template>
            </el-table-column>
          </template>
          <template v-if="radio === 2">
            <el-table-column
              :key="Math.random()"
              prop="classNum"
              label="班级"
              width="80"
              align="center"
              fixed
            ></el-table-column>
            <el-table-column
              :key="Math.random()"
              prop="teacherName"
              label="任课老师"
              width="100"
              align="center"
              fixed
            ></el-table-column>
            <el-table-column
              :key="Math.random()"
              prop="total"
              label="参考人数"
              width="100"
              fixed
              align="center"
            ></el-table-column>
            <el-table-column
              :key="Math.random()"
              prop="onlineTotal"
              label="上线人数"
              width="100"
              fixed
              align="center"
            ></el-table-column>
            <el-table-column
              :key="Math.random()"
              prop="onlineProportion"
              label="上线率"
              width="100"
              fixed
              align="center"
            ></el-table-column>
            <el-table-column
              :key="Math.random()"
              prop="onlineStability"
              label="	上线稳定性"
              width="100"
              fixed
              align="center"
            ></el-table-column>
            <el-table-column label="上线临界" align="center">
              <el-table-column
                label="人数"
                align="center"
                prop="upTotal"
              ></el-table-column>
              <el-table-column
                label="占比（%）"
                width="100"
                align="center"
                prop="upProportion"
              ></el-table-column>
              <el-table-column
                label="学生名单"
                prop="upStudents"
                align="center"
                width="350"
              >
                <template slot-scope="{ row }">
                  <div class="nameList">
                    <div
                      v-for="(item, index) in row.upStudents"
                      :key="'upStudents' + index"
                      type="text"
                      class="on"
                      @click="sutdentDetail(item)"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="未上线临界" align="center">
              <el-table-column
                label="人数"
                align="center"
                prop="downTotal"
              ></el-table-column>
              <el-table-column
                label="占比（%）"
                width="100"
                align="center"
                prop="downProportion"
              ></el-table-column>
              <el-table-column label="学生名单" align="center" width="350">
                <template slot-scope="{ row }">
                  <div class="nameList">
                    <div
                      v-for="(item, index) in row.downStudents"
                      :key="'downStudents' + index"
                      type="text"
                      :class="item.type === 0 ? 'on' : 'on'"
                      @click="sutdentDetail(item)"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
          </template>
          <template v-if="radio === 3">
            <el-table-column
              v-for="(item, index) in topWard"
              :key="index + 'track'"
              :prop="item.prop"
              :label="item.label"
              fixed
              width="110"
              align="center"
            >
            </el-table-column>
            <el-table-column label="上升波动" align="center">
              <el-table-column label="人数" align="center">
                <template slot-scope="{ row }">
                  {{ row.upTotal }}
                </template>
              </el-table-column>
              <el-table-column label="占比" align="center">
                <template slot-scope="{ row }">
                  {{ row.upProportion }}
                </template>
              </el-table-column>
              <el-table-column label="学生名单" width="220">
                <template slot-scope="{ row }">
                  <div class="nameList">
                    <div
                      v-for="(item, index) in row.upStudents"
                      :key="index"
                      :class="item.type === 0 ? 'on' : 'on'"
                      @click="sutdentDetail(item)"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="下降波动" align="center">
              <el-table-column label="人数" align="center">
                <template slot-scope="{ row }">
                  {{ row.downTotal }}
                </template>
              </el-table-column>
              <el-table-column label="占比" align="center">
                <template slot-scope="{ row }">
                  {{ row.downProportion }}
                </template>
              </el-table-column>
              <el-table-column label="学生名单" width="220">
                <template slot-scope="{ row }">
                  <div class="nameList">
                    <div
                      v-for="(item, index) in row.downStudents"
                      :key="index"
                      class="on"
                      @click="sutdentDetail(item)"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
          </template>
          <template v-if="radio === 4 || radio === 5">
            <el-table-column
              v-for="(item, index) in topWard"
              :key="index + 'track'"
              :prop="item.prop"
              :label="item.label"
              fixed
              width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              :label="radio === 4 ? '拔尖生' : '后进生'"
              align="center"
            >
              <el-table-column
                label="人数"
                prop="total"
                width="80"
                align="center"
              ></el-table-column>
              <el-table-column
                label="占比"
                prop="proportion"
                width="80"
                align="center"
              ></el-table-column>
              <el-table-column label="学生名单">
                <template slot-scope="{ row }">
                  <div class="nameList">
                    <div
                      v-for="(item, index) in row.students"
                      :key="index"
                      class="on"
                      @click="sutdentDetail(item)"
                    >
                      {{ item.name || "-" }}
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
          </template>
        </el-table>
        <div v-if="old.length > 10" class="btns">
          <el-button type="text" @click="isExtendChange">{{
            isExtend ? "收起" : "查看更多"
          }}</el-button>
        </div>
      </div>
      <!-- 提示 -->
      <div class="model-desc mt10">
        <div v-if="radio === 1">
          <div class="indent">
            跟踪生：仅当在报告设置中导入了跟踪生且跟踪生对应成功才显示。跟踪生（目标跟踪生）即为实现上线目标最稳定的学生群体，为目标基础保底儿。可从两方面设置
          </div>
          <div>
            一、（基于目标）结合学生入口成绩及（中）高考预期，确定学校跟踪生名单，各班数量不均。
          </div>
          <div>
            二、（基于管理）每班确定N名跟踪生，多为成绩稳定的尖子生，每班数量均匀。
          </div>
        </div>
        <div v-if="radio === 2">
          <div class="indent">
            临界生：仅当统计参数设置中进行了上线设置才显示。临界生即为处于上线边缘的学生，分为上线临界生（刚刚上线的学生）及未上线临界生（马上要上线的学生），稳定上线临界生成绩，提升未上线临界生成绩，保证上线率稳步提升。
          </div>
          <div>临界生的统计基数为参考人数</div>
        </div>
        <div v-if="radio === 3">
          <div class="indent">
            波动生：以某次考试为对比入口，检索出波动幅度较大的学生，分为上升波动（处于成绩提升趋势）及下降波动（处于成绩下降趋势）。对上升波动的学生给予肯定，激励其保持上升趋势；对下降波动学生，辅助分析原因并给予调整建议和鼓励。特别是排名靠前或临界状态的学生，可进一步查看学生报告了解历次发展情况，进行波动性深度分析。
          </div>
          <div>注：不参与统计的学生不参与此处计算，也不在此处显示</div>
        </div>
        <div v-if="radio === 4">
          <div class="indent">
            拔尖生（优生劣科）：了解拔尖生群体分布情况，以及优生劣科情况（拔尖生存在“严重偏科”的学科）。优生劣科可结合学生自身的学习特点分析，是否在本学科存在学有余力但方法不当或兴趣不高的情况，如存在应适当引导学生以总分为前提进行偏科学科的学习方法或学习状态的调整，同时也可提升班级总分高分群体占比。
          </div>
          <div>不参与统计的学生不参与此处计算，也不在此处显示</div>
        </div>
        <div v-if="radio === 5">
          <div class="indent">
            后进生（潜力学科）：了解后进生群体分布情况，以及潜力学科情况（后进生存在单科排名大幅度优于总分排名的学科）。后进生的潜力学科，可依据学生情况针对性分析，是否存在学有余力但学习动力不足的问题导致仅兴趣或天赋学科成绩较好，其他学科成绩滞后的情况。如存在应采取相应手段激发学生学习动力，并给予潜力学科肯定，如给予学生该兴趣学科的生涯规划指导等。
          </div>
          <div>不参与统计的学生不参与此处计算，也不在此处显示</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import barChart from "./chart.vue";
import CheckComponent from "./checkComponent.vue";
import { track, critical, topWard, wave } from "./TableLabel";
import exportBtn from "@/views/academic/components/exportBtn.vue";
import {
  getresearchreportfocusonstudenttrack,
  getstatInfo,
  getresearchreportfocusonstudentcritical,
  getresearchreportfocusonstudenttoporbackward,
  getresearchreportfocusonstudentwave,
  exportTeachingReport,
} from "@/core/api/academic/teachingReport";
import {
  Uniongetresearchreportfocusonstudentcritical,
  Uniongetresearchreportfocusonstudenttrack,
  Uniongetresearchreportfocusonstudentwave,
  Uniongetresearchreportfocusonstudenttoporbackward,
} from "@/core/api/academic/unionReport";
import { getstatcomparelist } from "@/core/api/academic/common";
import {
  getstatonlineoption,
  getstatclassoption,
} from "@/core/api/academic/common";

import axios from "axios";
export default {
  name: "FocusOnStudent",
  components: {
    barChart,
    CheckComponent,
    exportBtn,
  },
  props: {
    subject: {
      type: [String, Number, null],
      default: null,
    },
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      radio: 2,
      io: {},
      requestFunc: exportTeachingReport,
      track: track,
      topWard: topWard,
      waveLabel: wave,
      critical: critical,
      tableData: [],
      search: {
        interval: 150,
        onlineId: "",
      },
      topOrBackward: 20,
      classNumsList: [],
      options: [],
      scoreInfo: [],
      statId: this.$route.query.id,
      examType: this.$route.query.examType,
      subjectList: [],
      chartOptions: {},
      levelOptions: [],
      old: [],
      toporbackwardType: 1,
      wave: {
        upTop: "1000",
        upMax: "300",
        upStatId: "",
      },
      backWave: {
        downTop: "1000",
        downMax: "300",
        downStatId: "",
      },
      classNums: "",
      waveBackChartOptions: {},
      loading: false,
      isExtend: false,
      tableLoading: false,
    };
  },

  updated() {
    if (this.tableData.length) {
      this.$nextTick(() => {
        this.$refs.tableData.doLayout();
      });
    }
  },
  mounted() {
    if (
      this.$route.query.examType == 1 &&
      this.topData.topInfoOnlineVOs.length > 0
    ) {
      this.radio = 2;
      this.getData();
    } else if (
      this.$route.query.examType == 1 &&
      this.topData.isSchoolDisplayReportStudentRank == 1
    ) {
      this.radio = 3;
      this.radioChange();
    } else {
      this.radio = 1;
      this.radioChange();
    }
  },
  methods: {
    showCriticalStudent() {
      if (this.$route.query.examType == 1) {
        return this.topData.topInfoOnlineVOs.length > 0;
      }
      return true;
    },
    showStudentRank() {
      if (this.$route.query.examType == 1) {
        return this.topData.isSchoolDisplayReportStudentRank == 1;
      }
      return true;
    },
    isExtendChange() {
      this.tableLoading = true;
      this.isExtend = !this.isExtend;
      this.tableData = this.isExtend ? this.old : this.old.slice(0, 10);
      this.tableLoading = false;
    },
    async getData() {
      if (this.subject) {
        const res = await axios.all([
          this.getstatInfoData(),
          getstatclassoption({
            id: this.statId,
            requestPage: 2,
            viewSchoolId:
              this.examType == 1 ? this.$route.query.schoolId : null,
          }),
        ]);
        const [subjectList, classRes] = res;

        this.$nextTick(() => {
          this.classNumsList = classRes.data.data;
          this.classNums = classRes.data.data
            .map((item) => item.name)
            .toString();

          this.subjectList = subjectList.data.data.statSubjects;
          this.radioChange();
        });
      }
    },
    validateCritical() {
      if (!/(^[1-9]\d*$)/.test(this.search.interval)) {
        this.$message("请输入正整数");
        return;
      } else {
        this.getresearchreportfocusonstudentcriticalData();
      }
    },
    getstatcomparelistData() {
      this.loading = true;

      getstatcomparelist({ statId: this.statId, subjectId: this.subject }).then(
        (res) => {
          this.wave.upStatId = this.backWave.downStatId =
            (res.data.data.length && res.data.data[0].id) || null;
          this.options = res.data.data;
          this.loading = false;
          if (this.backWave.downStatId) {
            this.getschoolreportimportstudentTopWaveData();
          }
        }
      );
    },
    initOptions() {
      this.chartOptions = null;
      this.waveBackChartOptions = null;
      this.tableData = [];
    },
    validateBackWava() {
      if (
        !/(^[1-9]\d*$)/.test(this.backWave.downTop) ||
        !/(^[1-9]\d*$)/.test(this.backWave.downTop)
      ) {
        this.$message("请输入正整数");
        return;
      } else if (!this.backWave.downStatId) {
        this.$message("请选择对比考试");
        this.waveBackChartOptions = null;
        return;
      } else {
        this.getschoolreportimportstudentTopWaveData();
      }
    },
    validateWave() {
      if (
        !/(^[1-9]\d*$)/.test(this.wave.upTop) ||
        !/(^[1-9]\d*$)/.test(this.wave.upMax)
      ) {
        this.$message("请输入正整数");
        return;
      } else if (!this.wave.upStatId) {
        this.$message("请选择对比考试");
        this.chartOptions = null;
        return;
      } else {
        this.getschoolreportimportstudentTopWaveData();
      }
    },
    async getschoolreportimportstudentTopWaveData() {
      try {
        this.loading = true;
        let res;
        if (this.$route.query.examType == 1) {
          res = await Uniongetresearchreportfocusonstudentwave({
            ...this.backWave,
            ...this.wave,
            viewSchoolId: this.$route.query.schoolId,
            statId: this.statId,
            subjectId: this.subject,
            classNums: this.classNums,
          });
        } else {
          res = await getresearchreportfocusonstudentwave({
            ...this.backWave,
            ...this.wave,
            statId: this.statId,
            subjectId: this.subject,
            classNums: this.classNums,
          });
        }

        this.$nextTick(() => {
          this.tableData = res.data.data.slice(0, 10);
          this.isExtend = false;
          this.old = res.data.data;
        });
        this.loading = false;
        let upTotal = res.data.data.map((item) => item.upTotal);
        let upTotalValid = upTotal.some((item) => item);
        if (upTotal.length && upTotalValid) {
          this.chartOptions = {
            title: {
              text: "图1 - 各班上升波动人数  ",
              left: "center",
              textStyle: {
                fontSize: 14,
                fontWeight: "400",
              },
            },
            legend: {
              top: "5%",
            },
            grid: {
              bottom: res.data.data.length > 25 ? "20%" : "5%",
              containLabel: true,
            },
            dataZoom: [
              //滚动条
              {
                show: res.data.data.length > 25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: res.data.data.length - (res.data.data.length - 25),
                xAxisIndex: [0],
                bottom: "5%",
                left: "center",
              },
            ],
            xAxis: [
              {
                type: "category",
                data: res.data.data.map((item) => item.classNum),
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "rgba(0, 0, 0, 0.45)",
                  },
                  formatter: (val) => {
                    let arr = val.split("");
                    let index = 0;
                    let newArray = [];
                    while (index < arr.length) {
                      let data = arr.slice(index, (index += 5));
                      newArray.push(data.join(""));
                    }
                    let str = newArray.reduce(
                      (str, item) => str + "\n" + item,
                      ""
                    );
                    return str;
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                axisTick: {
                  show: false,
                },
              },
            ],
            series: [
              {
                name: "波动生", // 不同条柱
                type: "bar",
                label: {
                  show: true,
                  position: "top",
                  color: "inherit",
                  fontSize: 9,
                },
                barMaxWidth: 40,
                data: upTotal,
              },
            ],
          };
        } else {
          this.chartOptions = null;
        }
        let downTotal = res.data.data.map((item) => item.downTotal);
        let downTotalValid = downTotal.some((item) => item);
        if (downTotal.length && downTotalValid) {
          this.waveBackChartOptions = {
            title: {
              text: "图2 - 各班下降波动人数  ",
              left: "center",
              textStyle: {
                fontSize: 14,
                fontWeight: "400",
              },
            },
            legend: {
              top: "5%",
            },
            grid: {
              bottom: res.data.data.length > 25 ? "20%" : "5%",
              containLabel: true,
            },
            dataZoom: [
              //滚动条
              {
                show: res.data.data.length > 25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: res.data.data.length - (res.data.data.length - 25),
                xAxisIndex: [0],
                bottom: "5%",
                left: "center",
              },
            ],
            xAxis: [
              {
                type: "category",
                data: res.data.data.map((item) => item.classNum),
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "rgba(0, 0, 0, 0.45)",
                  },
                  formatter: (val) => {
                    let arr = val.split("");
                    let index = 0;
                    let newArray = [];
                    while (index < arr.length) {
                      let data = arr.slice(index, (index += 5));
                      newArray.push(data.join(""));
                    }
                    let str = newArray.reduce(
                      (str, item) => str + "\n" + item,
                      ""
                    );
                    return str;
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                axisTick: {
                  show: false,
                },
              },
            ],
            series: [
              {
                name: "波动生", // 不同条柱
                type: "bar",
                label: {
                  show: true,
                  position: "top",
                  color: "inherit",
                  fontSize: 9,
                },
                barMaxWidth: 40,
                data: downTotal,
              },
            ],
          };
        } else {
          this.waveBackChartOptions = null;
        }
      } catch {
        this.loading = false;
      }
    },
    getstatInfoData() {
      // 获取统计科目
      return getstatInfo({ id: this.statId });
    },
    sutdentDetail(item) {
      const { href } = this.$router.resolve({
        path: "/academic/reportStat/studentReport",
        query: {
          ...this.$route.query,
          studentId: item.studentId,
          id: this.statId,
        },
      });
      window.open(href, "_blank");
    },
    async getresearchreportfocusonstudenttrackData() {
      try {
        this.loading = true;
        let res;
        if (this.$route.query.examType == 1) {
          res = await Uniongetresearchreportfocusonstudenttrack({
            id: this.statId,
            viewSchoolId: this.$route.query.schoolId,
            subjectId: this.subject,
          });
        } else {
          res = await getresearchreportfocusonstudenttrack({
            id: this.statId,
            subjectId: this.subject,
          });
        }

        this.tableData =
          res.data.data.researchReportFocusOnStudentTrackStudentVOs;
        let valueValid =
          res.data.data.researchReportFocusOnStudentTrackClassVOs.some(
            (item) => item.total
          );
        this.old = res.data.data.researchReportFocusOnStudentTrackStudentVOs;
        if (valueValid) {
          let data = res.data.data.researchReportFocusOnStudentTrackClassVOs;
          this.chartOptions = {
            grid: {
              bottom: data.length > 25 ? "20%" : "5%",
              containLabel: true,
            },
            legend: { itemGap: 30 },

            dataZoom: [
              //滚动条
              {
                show: data.length > 25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: data.length - (data.length - 25),
                xAxisIndex: [0],
                bottom: "5%",
                left: "center",
              },
            ],
            xAxis: [
              {
                type: "category",
                data: data.map((item) => item.classNum),
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "rgba(0, 0, 0, 0.45)",
                  },
                  formatter: (val) => {
                    let arr = val.split("");
                    let index = 0;
                    let newArray = [];
                    while (index < arr.length) {
                      let data = arr.slice(index, (index += 5));
                      newArray.push(data.join(""));
                    }
                    let str = newArray.reduce(
                      (str, item) => str + "\n" + item,
                      ""
                    );
                    return str;
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                axisTick: {
                  show: false,
                },
              },
            ],
            series: [
              {
                name: "跟踪生", // 不同条柱
                type: "bar",
                label: {
                  show: true,
                  position: "top",
                  color: "inherit",
                  fontSize: 9,
                },
                barMaxWidth: 40,
                data: data.map((item) => item.total),
              },
            ],
          };
        } else {
          this.chartOptions = null;
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    getgetresearchreportfocusonstudenttoporbackwardConditions() {
      this.getgetresearchreportfocusonstudenttoporbackward();
    },
    validToporbackward() {
      if (!/(^[1-9]\d*$)/.test(this.topOrBackward)) {
        this.$message("排名应为正整数");
        return;
      } else {
        this.getgetresearchreportfocusonstudenttoporbackward();
      }
    },
    // 拔尖生 后进生
    async getgetresearchreportfocusonstudenttoporbackward() {
      try {
        this.loading = true;
        let res;
        if (this.$route.query.examType == 1) {
          res = await Uniongetresearchreportfocusonstudenttoporbackward({
            type: this.toporbackwardType,
            subjectId: this.subject,
            topOrBackward: this.topOrBackward,
            viewSchoolId: this.$route.query.schoolId,
            id: this.statId,
            classNums: this.classNums,
          });
        } else {
          res = await getresearchreportfocusonstudenttoporbackward({
            type: this.toporbackwardType,
            subjectId: this.subject,
            topOrBackward: this.topOrBackward,
            id: this.statId,
            classNums: this.classNums,
          });
        }

        this.$nextTick(() => {
          this.tableData = res.data.data.slice(0, 10);
          this.isExtend = false;
          this.old = res.data.data;
        });
        let data = res.data.data.map((item) => item.total);
        let valid = data.some((item) => item);
        if (data.length && valid) {
          this.chartOptions = {
            legend: { itemGap: 30 },
            grid: {
              bottom: res.data.data.length > 25 ? "20%" : "5%",
              containLabel: true,
            },
            dataZoom: [
              //滚动条
              {
                show: res.data.data.length > 25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: res.data.data.length - (res.data.data.length - 25),
                xAxisIndex: [0],
                bottom: "5%",
                left: "center",
              },
            ],
            xAxis: [
              {
                type: "category",
                data: res.data.data.map((item) => item.classNum),
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "rgba(0, 0, 0, 0.45)",
                  },
                  formatter: (val) => {
                    let arr = val.split("");
                    let index = 0;
                    let newArray = [];
                    while (index < arr.length) {
                      let data = arr.slice(index, (index += 5));
                      newArray.push(data.join(""));
                    }
                    let str = newArray.reduce(
                      (str, item) => str + "\n" + item,
                      ""
                    );
                    return str;
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                axisTick: {
                  show: false,
                },
              },
            ],
            series: [
              {
                name: this.toporbackwardType === 1 ? "拔尖生" : "后进生", // 不同条柱
                type: "bar",
                label: {
                  show: true,
                  position: "top",
                  color: "inherit",
                  fontSize: 9,
                },
                barMaxWidth: 40,
                data: data,
              },
            ],
          };
        } else {
          this.chartOptions = null;
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    radioChange() {
      this.initOptions();
      switch (this.radio) {
        case 1:
          this.getresearchreportfocusonstudenttrackData();
          break;
        case 2:
          this.search.interval = this.topData.criticalInterval;
          this.getCriticalConditions();
          break;
        case 3:
          this.getstatcomparelistData();
          break;
        case 4:
          this.toporbackwardType = 1;
          // this.topOrBackward = this.topData.segmentMaximum;
          this.topOrBackward = 20;
          this.getgetresearchreportfocusonstudenttoporbackwardConditions();
          break;
        case 5:
          this.toporbackwardType = 2;
          this.topOrBackward = 20;
          // this.topOrBackward = this.topData.segmentMaximum;
          this.getgetresearchreportfocusonstudenttoporbackwardConditions();
          break;
      }
    },
    async getCriticalConditions() {
      const onlinesRes = await getstatonlineoption({
        id: this.statId,
      });
      if (onlinesRes.data.data.length) {
        this.levelOptions = onlinesRes.data.data;
        this.search.onlineId = onlinesRes.data.data[0].id;
        this.getresearchreportfocusonstudentcriticalData();
      }
    },
    getCheckClassNum(val) {
      this.tableLoading = true;
      let arr = val.map((item) => item.name);
      this.tableData = this.old.filter((item) => arr.includes(item.classNum));
      this.tableLoading = false;
    },
    //临界生
    async getresearchreportfocusonstudentcriticalData() {
      try {
        this.loading = true;
        let res;
        if (this.$route.query.examType == 1) {
          res = await Uniongetresearchreportfocusonstudentcritical({
            statId: this.statId,
            onlineId: this.search.onlineId,
            criticalInterval: this.search.interval,
            classNums: this.classNums,
            viewSchoolId: this.$route.query.schoolId,
            subjectId: this.subject,
          });
        } else {
          res = await getresearchreportfocusonstudentcritical({
            statId: this.statId,
            onlineId: this.search.onlineId,
            criticalInterval: this.search.interval,
            classNums: this.classNums,
            subjectId: this.subject,
          });
        }
        this.$nextTick(() => {
          this.tableData = res.data.data.slice(0, 10);
          this.isExtend = false;
          this.old = res.data.data;
        });
        let downTotal = res.data.data.map((item) => {
          return -Number(item.downTotal);
        });
        let valid = downTotal.some((item) => item);
        if (downTotal.length && valid) {
          this.chartOptions = {
            legend: {
              itemGap: 35,
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
              valueFormatter: function (val) {
                return Math.abs(val);
              },
            },
            grid: {
              bottom: res.data.data.length > 25 ? "20%" : "5%",
              containLabel: true,
            },
            dataZoom: [
              //滚动条
              {
                show: res.data.data.length > 25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue: res.data.data.length - (res.data.data.length - 25),
                xAxisIndex: [0],
                bottom: "5%",
                left: "center",
              },
            ],
            xAxis: [
              {
                type: "category",
                data: res.data.data.map((item) => item.classNum),
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "rgba(0, 0, 0, 0.45)",
                  },
                  formatter: (val) => {
                    let arr = val.split("");
                    let index = 0;
                    let newArray = [];
                    while (index < arr.length) {
                      let data = arr.slice(index, (index += 5));
                      newArray.push(data.join(""));
                    }
                    let str = newArray.reduce(
                      (str, item) => str + "\n" + item,
                      ""
                    );
                    return str;
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",
                axisTick: {
                  show: false,
                },
              },
            ],
            series: [
              {
                name: "上限临界", // 不同条柱
                type: "bar",
                label: {
                  show: true,
                  position: "top",
                  color: "inherit",
                  fontSize: 9,
                },
                barMaxWidth: 40,
                data: res.data.data.map((item) => item.upTotal),
              },
              {
                name: "未上限临界", // 不同条柱
                type: "bar",
                color: "#F56C6C",
                label: {
                  show: true,
                  position: "bottom",
                  formatter: function (val) {
                    return Math.abs(val.data);
                  },
                  color: "inherit",
                  fontSize: 9,
                },
                barMaxWidth: 40,
                data: downTotal,
              },
            ],
          };
        } else {
          this.chartOptions = null;
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    getSubjectName(id) {
      if (!Number(id)) {
        return "总分";
      } else {
        const data = this.subjectList.find((item) => item.subjectId === id);
        return data.name;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.focusOnStudent {
  .radio {
    display: flex;
    justify-content: flex-end;
  }

  .tableList {
    margin-bottom: 24px;
  }

  .filter {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    justify-content: flex-start;
    align-items: center;

    .filter-item {
      display: flex;
      align-items: center;
      margin-right: 24px;

      span {
        margin: 0 8px;
      }

      .label {
        color: #080a09;
        font-size: 14px;
        margin-right: 8px;
      }
    }

    .tip {
      font-size: 12px;
      color: #737677;
      margin-left: 10px;
    }
  }

  .tag {
    font-size: 14px;
    border: 1px dashed #2474ed;
    color: #737677;
    background-color: #f2f8fe;
    box-sizing: border-box;
    padding: 12px;
    margin-bottom: 24px;
    line-height: 1.5;

    div {
      margin-bottom: 12px;
    }

    & :last-child {
      margin: 0;
    }
  }
}

.nameList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  div {
    text-decoration: underline;
    cursor: pointer;
    margin-right: 10px;
  }
}

.on {
  color: #2474ed;
}

.off {
  color: #ff9776;
}

.subjectShit {
  color: #f56c6c;
}

.btns {
  text-align: center;
  margin: 10px 0;
}
</style>
