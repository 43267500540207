<template>
  <div class="teachingReport">
    <div class="topCard">
      <tag> </tag>
      <div class="title-box">
        <div v-if="$route.query.ruleId > 0" class="new-exam-tag">新高考</div>

        <div class="title">
          {{ topData.name }}
          <div :class="['sub-tag', topData.type == 99 ? 'other' : 'general']">
            {{ getExamType(topData.type) }}
          </div>
        </div>
      </div>
      <div class="back" @click="back">
        <i class="el-icon-arrow-left"></i>返回
      </div>
      <export-btn
        btn-type="primary"
        :request-func="requestFunc"
        :params="{
          id: $route.query.id,
          type: 0,
          subjectId: activeName,
        }"
        text="导出全部报表"
      >
        <div class="sub-title">
          <div>
            {{ topData.examDate }}&nbsp;&nbsp;&nbsp;&nbsp;<span
              v-if="level == 2"
              >初</span
            ><span v-if="level == 3">高</span>{{ topData.year }}级
          </div>
        </div>
      </export-btn>
      <div class="model-title">教研报告</div>
      <div class="subject-tab">
        <el-tabs
          v-model="activeName"
          style="margin-bottom: 24px"
          @tab-click="handleChange"
        >
          <el-tab-pane
            v-for="(item, index) in tabs"
            :key="index"
            :disabled="tabsDisabled"
            :label="item.name"
            :name="item.id.toString()"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div v-if="topData.year" class="dashboard">
        <div class="examinfo-box">
          <div class="fl">
            <p>
              <i>{{ topData.classCount }}</i
              >个班级
            </p>
            <p>
              <i>{{ topData.studentCount }}</i
              >名学生
            </p>
          </div>
          <span class="info-line"></span>
          <div class="refer-score fl">
            <p>最高分{{ topData.highestScore }}</p>
            <p>最低分{{ topData.lowestScore }}</p>
          </div>
        </div>
        <div class="report-scorebox bg-lightblue">
          <p class="ft-blue">{{ topData.averageScore }}</p>
          <span>平均分</span>
        </div>
        <ul
          v-isUnionShow="{ type: 'length', field: 'topInfoOnlineVOs' }"
          class="report-rankingbox bg-lightorange ml30"
        >
          <li v-for="(item, index) in topData.topInfoOnlineVOs" :key="index">
            <p class="ft-orange">{{ item.value }}%</p>
            <span>{{ item.name }}上线率</span>
          </li>
        </ul>
        <ul class="report-rankingbox bg-lightgreen ml30">
          <li v-for="(item, index) in topData.topInfoRatioVOs" :key="index">
            <p class="ft-green">{{ item.value }}%</p>
            <span class="label">{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="activeName && topData.year">
      <branch id="branch" :key="activeName + 'branch'" :top-data="topData" />
      <test-analysis
        id="testAnalysis"
        :key="activeName + 'test-analysis'"
        :top-data="topData"
        :subject="activeName"
      />
      <classCompetitive
        id="classCompetitive"
        :key="activeName + 'classCompetitive'"
        v-isUnionShow="'statClass'"
        :subject="activeName"
        :top-data="topData"
      />
      <contribution
        id="contribution"
        :key="activeName + 'contribution'"
        v-isUnionShow="{ type: 'length', field: 'topInfoOnlineVOs' }"
        :subject="activeName"
        :top-data="topData"
      />
      <focusOnStudenet
        id="focusOnStudenet"
        :key="activeName + 'focusOnStudenet'"
        :top-data="topData"
        :subject="activeName"
      />
      <developAnalysis
        v-if="activeName"
        id="developAnalysis"
        :key="activeName + 'developAnalysis'"
        :top-data="topData"
        :subject="activeName"
      />
    </div>
    <pageNav :menu-list="menuList" />
  </div>
</template>

<script>
import tag from "../components/tag.vue";
import branch from "./components/branch.vue";
import classCompetitive from "./components/classCompetitive.vue";
import testAnalysis from "./components/testAnalysis.vue";
import contribution from "./components/contribution.vue";
import focusOnStudenet from "./components/focusOnStudenet.vue";
import developAnalysis from "./components/developAnalysis.vue";
import pageNav from "../components/pageNav.vue";
import { examTypeOptions } from "@/core/util/constdata";
import { fileDownloadByUrl } from "@/core/util/util";
import exportBtn from "@/views/academic/components/exportBtn";
import {
  getresearchreporttopinfo,
  downloadresearchpdfreport,
  exportTeachingReport,
} from "@/core/api/academic/teachingReport";
import { unionGetschoolreporttopinfo } from "@/core/api/academic/unionReport";
import { getstatsubjectoption } from "@/core/api/academic/common";
export default {
  name: "TeachingReport",
  components: {
    tag,
    branch,
    testAnalysis,
    classCompetitive,
    contribution,
    focusOnStudenet,
    developAnalysis,
    pageNav,
    exportBtn,
  },
  data() {
    return {
      requestFunc: exportTeachingReport,
      activeName: null,
      statId: "",
      tabs: {},
      topData: {
        topInfoOnlineVOs: [],
      },
      level: null,
      tabsDisabled: false,
      examType: this.$route.query.examType,
      menuList: [
        {
          name: "成绩分布",
          id: "branch",
        },
        {
          name: "试卷分析",
          id: "testAnalysis",
        },
        {
          name: "班级竞争力",
          id: "classCompetitive",
        },
        {
          name: "学科贡献分析",
          id: "contribution",
        },
        {
          name: "重点关注学生",
          id: "focusOnStudenet",
        },
        {
          name: "发展性分析",
          id: "developAnalysis",
        },
      ],
    };
  },
  mounted() {
    const { id, level } = this.$route.query;
    this.statId = id;
    this.level = level;
    this.getexamsubjectListData(id);
  },
  methods: {
    async exportPDF() {
      const res = await downloadresearchpdfreport({
        id: this.statId,
        subjectId: this.activeName,
      });
      if (res.data.data.url) {
        fileDownloadByUrl(res.data.data);
      }
    },
    back() {
      this.$router.go(-1);
    },
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value === type);
        return data.label;
      }
    },
    handleChange() {
      this.getresearchreporttopinfoData(this.statId, this.activeName);
      this.$store.commit("report/SET_PROP", this.activeName);
      const data = this.tabs.find((item) => item.id == this.activeName);
      this.$store.commit("report/SET_TOPDATA", data);
    },
    async getexamsubjectListData(id) {
      const res = await getstatsubjectoption({
        id: id,
        hasTotal: 0,
        viewSchoolId: this.examType == 1 ? this.$route.query.schoolId : null,
        requestPage: 2,
      });
      this.tabs = res.data.data;
      if (res.data.data.length) {
        this.activeName = res.data.data[0].id.toString();
      }

      this.getresearchreporttopinfoData(id, res.data.data[0].id);
      this.$store.commit("report/SET_PROP", res.data.data[0].id);
    },
    async getresearchreporttopinfoData(id, subjectId) {
      this.topData = {};
      this.tabsDisabled = true;
      try {
        let res;
        if (this.$route.query.examType == 1) {
          res = await unionGetschoolreporttopinfo({
            id: id,
            subjectId: subjectId,
            viewSchoolId: this.$route.query.schoolId,
          });
        } else {
          res = await getresearchreporttopinfo({
            id: id,
            subjectId: subjectId,
          });
        }
        this.topData = res.data.data;
        if (this.examType == 1 && !this.topData.topInfoOnlineVOs.length) {
          this.menuList = this.menuList.filter(
            (item) => item.name != "学科贡献分析"
          );
        }
        if (this.examType == 1 && this.topData.statClass != 1) {
          this.menuList = this.menuList.filter(
            (item) => item.name != "班级竞争力"
          );
        }
        this.$store.commit("report/SET_TOPDATA", this.topData);
        setTimeout(() => {
          this.tabsDisabled = false;
        }, 1000);
      } catch {
        this.tabsDisabled = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.teachingReport {
  width: 1200px;
  margin: 0 auto;
}

::v-deep .el-tabs__nav-wrap::after {
  display: none;
}

::v-deep .el-tabs__active-bar {
  display: none;
}

::v-deep .el-tabs__item {
  padding: 0 24px !important;
  height: 32px;
  line-height: 32px;
  margin: 0 18px;
  box-sizing: border-box;
}

::v-deep .el-tabs {
  .is-active {
    border: 1px solid #3f87f4;
    border-radius: 16px;
    background-color: #f2faff;
  }
}

::v-deep .el-icon-arrow-left {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}

::v-deep .el-icon-arrow-right {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}

::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #2474ed;
  border-color: #2474ed;

  &:hover {
    color: #fff;
  }
}

::v-deep .el-radio-button__inner {
  &:hover {
    color: #2474ed;
  }
}

ul {
  margin-bottom: 0;
}
</style>
